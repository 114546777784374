import React, {
	useState,
	useEffect,
	useRef,
	useCallback,
	useMemo,
} from 'react';
import { getCerificatesByUser, getManuscritaByUser } from '../../redux/actions/SignAction';
import { connect, useDispatch, useSelector } from 'react-redux';
import { POP } from '../../redux/types/SignTypes';
import { APP_KEY, MAX_FILE_DOWNLOAD } from '../../redux/types/ApiConstants';

import clsx from 'clsx';

// Mui
import { Box, Collapse, IconButton, useTheme } from '@mui/material';
import {
	Button,
	Grid,
	Menu,
	MenuItem,
	Modal,
	Tab,
	Tabs,
	Typography,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { useMediaQuery } from '@material-ui/core';

//Icons
import FolderIcon from '@mui/icons-material/Folder';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import EditIcon from '@mui/icons-material/Edit';

import ModalGetFIleCloud from '../ConsultSignedDocument/ModalGetFIleCloud';
import PendingToSign from './PendingToSign';
import PendingToObserve from './PendingToObserve';
import Loading from '../../components/Loading';
import ModalFirmaAvanzada from '../Firmar/FirmaAvanzada/ModalFirmaAvanzada';
import EnhancedTableToolbar from './EnhancedTableToolbar';

import {
	getBase64OfSelectedFile,
	getListSignedDocument,
	deleteFile,
	getListSignedDocumentCF,
	getListDocumentPendingObserve,
	getListDocumentPending,
} from '../../redux/actions/ConsultSignedDocumentsAction';
import {
	getComparator,
	orderListype,
	stableSort,
	orderListypeMasivo,
} from '../../helpers/ConsultSignedDocuments/stableSort';
import ModalDeleteFiles from './ModalDeleteFiles';
import ModalFirmaAvanzadaCF from '../Firmar/CircuitoFirmas/modales/ModalFirmaAvanzadaCF';
import { truncarNombreCompleto } from '../../helpers/helpNames';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles(theme => ({
	resignCont: {
		display: 'flex',
		justifyContent: 'start',
		[theme.breakpoints.up('lg')]: {
			justifyContent: 'space-between',
		},
	},
	nombre: {
		font: 'normal normal normal 14px/19px Mulish;',
		lineHeight: '19px',
		color: '#000000',
		letterSpacing: '0px',
		fontWeight: '400',
		opacity: 1,
	},
	ordenar: {
		font: 'normal normal normal 13px/16px Mulish',
		textTransform: 'inherit',
		float: 'right',
		color: '#706F6F',
		letterSpacing: '0px',
		fontWeight: '500',
		opacity: 1,
		marginTop: '10px',
		marginRight: '30px',
		[theme.breakpoints.down('xs')]: {
			// Reglas para pantallas inferiores a 600px
			float: 'none', // Elimina el float
			width: '100%', // Ocupa el 100% del ancho
			marginTop: '10px', // Elimina el margin top
			marginRight: '0px', // Elimina el margin right
			textAlign: 'center', // Centrar el texto si es necesario
		},
	},
	fila: {
		// cursor: 'pointer',
		// '&:hover': {
		// 	// '& span': {
		// 	// 	opacity: '1',
		// 	// },
		// },
	},
	filaFolder: {
		// cursor: 'pointer',
		'& span': {
			opacity: '1',
		},
	},
	tabs: {
		margin: '15px 25px 0',
	},
	seleccion: {
		textTransform: 'none',
		fontFamily: 'Mulish',
		fontSize: '16px',
		lineHeight: '28px',
		fontWeight: '400',
		fontStyle: 'normal',
	},
	fecha: {
		font: 'normal normal normal 12px/15px Mulish;',
		color: '#575756',
		letterSpacing: '0px',
		opacity: 1,
	},
	title: {
		margin: 0,
		height: '100px',
		paddingTop: '20px',
		color: 'white',
		paddingLeft: '2%',
	},
	resign: {
		fontFamily: 'Mulish',
		fontStyle: 'normal',
		fontWeight: '600',
		fontSize: '12px',
		lineHeight: '14px',
		color: '#E55200',
	},
	origen: {
		font: 'normal normal normal 12px/15px Mulish;',
		color: '#4F4F4F',
		letterSpacing: '0px',
		opacity: 1,
	},
	paper: {
		padding: '20px',

		borderRadius: '10px',
	},
	table: {
		borderCollapse: 'collapse',
		border: 0,
		padding: 0,
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	buttonMargin: {
		margin: '2px',
	},
	image: {
		width: '25px',
		height: '25px',
	},
	visible: {
		visibility: 'visible',
	},
	oculto: {
		visibility: 'hidden',
	},
	titleEncabezado: {
		fontFamily: 'Mulish',
		fontStyle: 'normal',
		fontWeight: '700',
		fontSize: '12px',
		color: '#E55200',
		lineHeight: '16px',
	},
	titleEncabezado2: {
		color: '#E55200',
		fontFamily: 'Mulish',
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: '700',
		lineHeight: '16px',
		paddingLeft: '18px',
	},
	noVisible: {
		opacity: 0,
	},
}));

const useStylesCheckOrange = makeStyles({
	root: {
		'&:hover': {
			backgroundColor: 'black',
		},
	},
	icon: {
		borderRadius: 0,
		width: 16,
		height: 16,
		opacity: '0',
		boxShadow: 'inset 0 0 0 2px rgba(157, 157, 156,1.0)',
		backgroundColor: 'white',
		backgroundImage:
			'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
		'$root.Mui-focusVisible &': {
			outline: '2px auto rgba(19,124,189,.6)',
			outlineOffset: 2,
		},
	},
	checkedIcon: {
		opacity: '1',
		backgroundColor: 'white',
		backgroundImage:
			'linear-gradient(10deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
		'&:before': {
			display: 'block',
			width: 16,
			height: 16,
			backgroundImage:
				"url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
				" fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
				"1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='rgb(229, 82, 0)'/%3E%3C/svg%3E\")",
			content: '""',
		},
		'input:hover ~ &': {
			backgroundColor: 'white',
		},
	},
});

function StyledCheckbox(props) {
	const classes = useStylesCheckOrange();
	return (
		<Checkbox
			className={classes.root}
			disableRipple
			checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
			icon={<span className={classes.icon} />}
			inputProps={{ 'aria-label': 'decorative checkbox' }}
			{...props}
		/>
	);
}

const DateSign = props => {
	const classes = useStyles();
	const { fechaFirma } = props;
	var date = new Date(fechaFirma);
	return (
		<Grid container spacing={1} direction='row'>
			<Grid item xs={6} style={{ marginLeft: '2px' }}>
				<Typography className={classes.fecha}>
					{date.getDate() +
						'/' +
						(date.getMonth() + 1) +
						'/' +
						date.getFullYear()}
					<br></br>
					{date
						.toLocaleString('en-US', {
							hour: 'numeric',
							minute: 'numeric',
							second: 'numeric',
							hour12: true,
						})
						.toLowerCase()}
				</Typography>
			</Grid>
		</Grid>
	);
};
const Resign = ({
	index,
	indexRow,
	isCircuit,
	isFolder = false,
	backToSignOneFile,
	selected,
}) => {
	const classes = useStyles();

	return (
		<Grid container className={classes.resignCont}>
			{!isFolder ? (
				<>
					{window.innerWidth < 1265 ? (
						<>
							{isCircuit && indexRow !== index && (
								<Grid
									item
									xs={6}
									style={{
										display: 'contents',
									}}
								>
									<Button className='btnChange2'>Circuito de firmas</Button>
								</Grid>
							)}
							<>
								{selected.length === 0 && (
									<Grid item xs={12}>
										{indexRow === index && (
											<Grid
												container
												style={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													color: '#E55200',
												}}
											>
												<Grid
													item
													xs={2}
													style={{
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
													}}
												>
													<Tooltip
														title='Volver a firmar'
														style={{
															width: '18px',
															height: '18px',
														}}
													>
														<EditIcon />
													</Tooltip>
												</Grid>
												<Grid
													item
													xs={10}
													onClick={e => backToSignOneFile(e, indexRow)}
													style={{
														textAlign: 'left',
													}}
												>
													{/* Este es el de hover pero en vista pequeña */}
													<Typography className={classes.resign}>
														Volver a firmar
													</Typography>
												</Grid>
											</Grid>
										)}
									</Grid>
								)}
							</>
						</>
					) : (
						<>
							<Grid item xs={6}>
								{isCircuit && (
									<Button className='btnChange2'>Circuito de firmas</Button>
								)}
							</Grid>
							{selected.length === 0 && (
								<Grid item xs={6}>
									{indexRow == index && (
										<Grid
											container
											style={{
												display: 'flex',
												justifyContent: 'space-between',
												alignItems: 'center',
												color: '#E55200',
											}}
										>
											<Grid
												item
												xs={2}
												style={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
												}}
											>
												<Tooltip
													title='Volver a firmar'
													style={{
														width: '18px',
														height: '18px',
													}}
												>
													<EditIcon />
												</Tooltip>
											</Grid>
											<Grid
												item
												xs={10}
												onClick={e => backToSignOneFile(e, indexRow)}
											>
												{/* Este es el de hover */}
												<Typography className={classes.resign}>
													Volver a firmar
												</Typography>
											</Grid>
										</Grid>
									)}
								</Grid>
							)}
						</>
					)}
				</>
			) : (
				<>
					{selected.length === 0 && (
						<Grid item xs={12}>
							{indexRow === index && (
								<Grid
									container
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										color: '#E55200',
									}}
								>
									<Grid
										item
										xs={2}
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										<Tooltip
											title='Volver a firmar'
											style={{
												width: '18px',
												height: '18px',
											}}
										>
											<EditIcon />
										</Tooltip>
									</Grid>
									<Grid
										item
										xs={10}
										onClick={e => {
											backToSignOneFile(e, indexRow);
										}}
									>
										<Typography className={classes.resign}>
											Volver a firmar
										</Typography>
									</Grid>
								</Grid>
							)}
						</Grid>
					)}
				</>
			)}
		</Grid>
	);
};

const ResignMassive = ({
	index,
	indexRow,
	isCircuit,
	isFolder = false,
	backToSignOneFile,
	selected,
}) => {
	const classes = useStyles();

	return (
		<Grid container className={classes.resignCont}>
			{!isFolder ? (
				<>
					{window.innerWidth < 1265 ? (
						<>
							{isCircuit && indexRow !== index && (
								<Grid
									item
									xs={6}
									style={{
										display: 'contents',
									}}
								>
									{/* <Button className='btnChange2'>Circuito de firmas</Button> */}
								</Grid>
							)}
							<>
								{selected.length === 0 && (
									<Grid item xs={12}>
										{indexRow === index && (
											<Grid
												container
												style={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													color: '#E55200',
												}}
											>
												<Grid
													item
													xs={2}
													style={{
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
													}}
												>
													{/* <Tooltip
														title='Volver a firmar'
														style={{
															width: '18px',
															height: '18px',
														}}
													>
														<EditIcon />
													</Tooltip> */}
												</Grid>
												<Grid
													item
													xs={10}
													// onClick={e => backToSignOneFile(e, indexRow)}
													style={{
														textAlign: 'left',
													}}
												>
													{/* Este es el de hover pero en vista pequeña */}
													{/* <Typography className={classes.resign}>
														Volver a firmar
													</Typography> */}
												</Grid>
											</Grid>
										)}
									</Grid>
								)}
							</>
						</>
					) : (
						<>
							<Grid item xs={6}>
								{/* {isCircuit && (
									// <Button className='btnChange2'>Circuito de firmas</Button>
								)} */}
							</Grid>
							{selected.length === 0 && (
								<Grid item xs={6}>
									{indexRow == index && (
										<Grid
											container
											style={{
												display: 'flex',
												justifyContent: 'space-between',
												alignItems: 'center',
												color: '#E55200',
											}}
										></Grid>
									)}
								</Grid>
							)}
						</>
					)}
				</>
			) : (
				<>
					{selected.length === 0 && (
						<Grid item xs={12}>
							{indexRow === index && (
								<Grid
									container
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										color: '#E55200',
									}}
								>
									<Grid
										item
										xs={2}
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										<Tooltip
											title='Volver a firmar'
											style={{
												width: '18px',
												height: '18px',
											}}
										>
											<EditIcon />
										</Tooltip>
									</Grid>
									<Grid
										item
										xs={10}
										onClick={e => {
											backToSignOneFile(e, indexRow);
										}}
									>
										<Typography className={classes.resign}>
											Volver a firmar
										</Typography>
									</Grid>
								</Grid>
							)}
						</Grid>
					)}
				</>
			)}
		</Grid>
	);
};

// * Encabezado tabla Completados

const headCells = [
	{
		id: 'nombrereal',
		align: 'start',
		numeric: false,
		disablePadding: true,
		label: 'Carpeta / Documento',
		width: '36%',
		style: true,
	},
	{
		id: 'descripcionOrigenFirma',
		align: 'start',
		numeric: false,
		disablePadding: true,
		label: 'Certificado',
		width: '20%',
		style: false,
	},
	{
		id: 'fechaFirma',
		align: 'start',
		numeric: false,
		disablePadding: true,
		label: 'Fecha de firma',
		width: '15%',
		style: false,
	},
	{
		id: '',
		align: 'start',
		numeric: false,
		disablePadding: true,
		label: '',
		width: '29%',
		style: false,
	},
];

const headCellsMassive = [
	{
		id: 'nombrereal',
		align: 'start',
		numeric: false,
		disablePadding: true,
		label: 'Carpeta / Documento',
		width: '40%',
		style: true,
	},
	{
		id: 'creador',
		align: 'start',
		numeric: false,
		disablePadding: true,
		label: 'Creador del Circuito',
		width: '25%',
		style: false,
	},
	{
		id: 'fechaCreacion',
		align: 'start',
		numeric: false,
		disablePadding: true,
		label: 'Fecha Creación',
		width: '16%',
		style: false,
	},

	{
		id: '',
		align: 'start',
		numeric: false,
		disablePadding: true,
		label: '',
		width: '30%',
		style: false,
	},
];

function EnhancedTableHead(props) {
	const { value: propsValue, order, orderBy, onRequestSort, url } = props;
	const classes = useStyles();
	const location = useLocation();
	const isHome = location.pathname === '/home';
	const value = isHome ? 2 : propsValue;
	const isSmallScreen = useMediaQuery('(max-width:900px)');
	const [count, setCount] = useState(0);

	const createSortHandler = property => event => {
		onRequestSort(event, property);
		setCount(count + 1);
	};

	// Conditionally define headCellsMassive
	const headCellsMassiveToUse = useMemo(() => {
		if (isHome && isSmallScreen) {
			// Only include the 'nombre' column and the last empty column
			return [
				{
					id: 'nombrereal',
					align: 'start',
					numeric: false,
					disablePadding: true,
					label: 'Carpeta / Documento',
					width: '100%',
					style: true,
				},
				{
					id: '',
					align: 'start',
					numeric: false,
					disablePadding: true,
					label: '',
					width: '0%',
					style: false,
				},
			];
		} else {
			// Use the original headCellsMassive
			return headCellsMassive;
		}
	}, [isHome, isSmallScreen]);

	return (
		<>
			{value === 0 && (
				<TableHead className={'headerTableDocumentSigned'}>
					<TableRow>
						<TableCell padding='checkbox' />
						{headCells.map(headCell => (
							<TableCell
								key={headCell.id}
								width={headCell.width}
								align={headCell.align}
								className={
									headCell.style
										? classes.titleEncabezado2
										: classes.titleEncabezado
								}
								padding={headCell.disablePadding ? 'none' : 'default'}
								sortDirection={orderBy === headCell.id ? order : false}
							>
								{headCell.id !== '' && (
									<TableSortLabel
										className={
											headCell.style
												? classes.titleEncabezado2
												: classes.titleEncabezado
										}
										active={orderBy === headCell.id && count > 0}
										direction={orderBy === headCell.id ? order : 'desc'}
										onClick={createSortHandler(headCell.id)}
									>
										<span
											className={
												headCell.style
													? classes.titleEncabezado2
													: classes.titleEncabezado
											}
										>
											{headCell.label}
										</span>
										{orderBy === headCell.id ? (
											<span className={classes.visuallyHidden}>
												{order === 'desc'
													? 'sorted descending'
													: 'sorted ascending'}
											</span>
										) : null}
									</TableSortLabel>
								)}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
			)}
			{value === 2 && (
				<TableHead className={'headerTableDocumentSigned'}>
					<TableRow>
						<TableCell padding='checkbox' />
						{headCellsMassiveToUse.map(headCell => (
							<TableCell
								key={headCell.id}
								width={headCell.width}
								align={headCell.align}
								className={
									headCell.style
										? classes.titleEncabezado2
										: classes.titleEncabezado
								}
								padding={headCell.disablePadding ? 'none' : 'default'}
								sortDirection={orderBy === headCell.id ? order : false}
							>
								{headCell.id !== '' && (
									<TableSortLabel
										className={
											headCell.style
												? classes.titleEncabezado2
												: classes.titleEncabezado
										}
										active={orderBy === headCell.id && count > 0}
										direction={orderBy === headCell.id ? order : 'desc'}
										onClick={createSortHandler(headCell.id)}
									>
										<span
											className={
												headCell.style
													? classes.titleEncabezado2
													: classes.titleEncabezado
											}
										>
											{headCell.label}
										</span>
										{orderBy === headCell.id ? (
											<span className={classes.visuallyHidden}>
												{order === 'desc'
													? 'sorted descending'
													: 'sorted ascending'}
											</span>
										) : null}
									</TableSortLabel>
								)}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
			)}
		</>
	);
}
const orderCertificate = name => {
	let certificate = name.split('-');
	if (certificate.length > 1) {
		if (certificate[1].includes('validateOTP'))
			return (
				<>
					Cert.{' '}
					<span style={{ textTransform: 'capitalize' }}>
						{truncarNombreCompleto(certificate[0].toLowerCase())}
					</span>
				</>
			);
		else
			return (
				<>
					{truncarNombreCompleto(certificate[1])} <br /> Cert.{' '}
					<span style={{ textTransform: 'capitalize' }}>{certificate[0]}</span>
				</>
			);
	} else return truncarNombreCompleto(certificate);
};

const cutName = name =>
	name.length > 30 ? (
		<Tooltip title={name}>
			<div>{name.substring(0, 30).concat('...')}</div>
		</Tooltip>
	) : (
		<div>{name}</div>
	);

const ConsultSignedDocuments = props => {
	const location = useLocation();
	const isHome =
		location.pathname === '/home' || location.pathname === '/home/';
	const {
		rows,
		rowsPending,
		rowsObserve,
		pop,
		getListDocumentCF,
		deleteFile,
		loadingDocument,
		getSignManuscrita,
		getCertificates,
	} = props;
	const classes = useStyles();
	const theme = useTheme();
	const [services, setServices] = useState({
		sms: false,
		whatsapp: false,
	});
	const ref = useRef(null);
	const { enqueueSnackbar } = useSnackbar();

	const [indexRow, setIndex] = useState(null);
	const [isAllSelected, setAllSelected] = useState(false);
	const [isFilterIsNull, setIsFilterIsNull] = useState(false);
	const [loading, setLoading] = useState(false);
	const [modalDeleteFiles, setModalDeleteFiles] = useState(false);
	const [order, setOrder] = useState('desc');
	const [orderBy, setOrderBy] = useState('fechaFirma');
	const [orderBym, setOrderBym] = useState('fechaCreacion');
	const [orderMenu, setOrderMenu] = useState(null);
	const [p7z, setP7z] = useState(0);
	const [page, setPage] = useState(0);
	const [rowsFilter, setRowsFilter] = useState(rows);
	const [rowsPendingFilter, setRowsPendingFilter] = useState(rowsPending);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [selected, setSelected] = useState([]);
	const [statusModalAvanzado, setStatusModalAvanzado] = useState(false);
	const [statusModalCloudSelect, setStatusModalCloudSelect] = useState(false);
	const [statusModalPop, setStatusModalPop] = useState(false);
	const [tabSelected, setTabSelected] = useState(0);
	const [rowsObserveFilter, setRowsObserveFilter] = useState(rowsObserve);
	const [value, setValue] = useState(0);
	const [openedRowIndex, setOpenedRowIndex] = useState(-1);
	const [idOneFile, setIdOneFile] = useState();
	const [idsFolder, setIdsFolder] = useState([]);
	const [valueSearch, setValueSearch] = useState('');
	const [firstTime, setFisrtTime] = useState(false);
	const [idDocsSelected, setIdDocsSelected] = useState([]);
	const [idCarpetCircuit, setIdCarpetCircuit] = useState([]);
	const [idsInFolder, setIdsInFolder] = useState([]);
	const [disabledBtnDelete, setDisabledBtnDelete] = useState(false);
	const [user, setUser] = useState();
	const [hasFetched, setHasFetched] = useState(false);
	// const [circuitRows, setCircuitRows] = useState([]);
	const [rowsMassiveFilter, setRowsMassiveFilter] = useState([]);
	const dispatch = useDispatch();
	const userInfo = useSelector(({ auth }) => auth.userInfo);
  const [totalDocumentosObservar, setTotalDocumentosObservar] = useState(0);
	const [totalDocumentosPendientes, setTotalDocumentosPendientes] = useState(0);

	
	
	const isSmallScreen = useMediaQuery('(max-width:900px)');
	const CryptoJS = require('crypto-js');
	const key = APP_KEY;
	useEffect(() => {
		if (isHome) {
			setTabSelected(2);
		}
	}, [isHome]);
	useEffect(() => {
		if (isHome) {
			setRowsMassiveFilter(rowsPending);
		}
	}, [isHome, rowsPending]);

	const valorEncriptado = (() => {
		// Aqui estamos revisando si rows tiene algún elemento o si todos los elementos tienen un valor encriptado
		const hasEncriptadoValue = rows.some(
			objeto => objeto.encriptado !== undefined && objeto.encriptado !== null
		);

		if (hasEncriptadoValue) {
			// Si rows tiene elementos con valor encriptado, realiza el mapeo directamente
			return rows
				.map(objeto => objeto.encriptado)
				.filter(valor => valor !== undefined)[0];
		} else {
			// Si rows no tiene elementos con valor encriptado, realiza el mapeo en rowsPending
			const valorEncriptadoRowsPending = rowsPending
				.map(objeto => objeto.encriptado)
				.filter(valor => valor !== undefined)[0];

			return valorEncriptadoRowsPending;
		}
		//Se puede dar otro caso y es cuando el firmante es nuevo y no ha firmado ningun documento de circuito, y solo
		//ha firmado documentos pero desde firma masiva
	})();

	const filteredIds = idCarpetCircuit.filter(id => {
		const row = rows.find(row => row.id === id);
		return row && row.documentCircuito !== null;
	});
	const paperStyles = useMemo(() => {
		// Base styles
		let styles = {};

		if (isHome) {
			if (isSmallScreen) {
				// When on Home page and screen is less than 900px
				styles = {
					marginTop: '5%',
					marginLeft: '20px',
					marginRight: '20px',
				};
			} else {
				styles = {
					marginTop: '0px',
					marginBottom: '25px',
					marginLeft: '0',
					marginRight: '0',
				};
			}
		} else {
			styles = {
				marginTop: '-25px',
				marginBottom: '0px',
				marginLeft: '2%',
				marginRight: '2%',
			};
		}

		return styles;
	}, [isHome, isSmallScreen]);

	useEffect(() => {
		(async () => {
			await getSignManuscrita(enqueueSnackbar);
			await getCertificates(enqueueSnackbar, onClose, setServices);
		})();
	}, []);

	useEffect(() => {
		if (valorEncriptado) {
			const procesar = valorEncriptado
				.replace(/[-]/g, '+')
				.replace(/[_]/g, '/');
			let decifrado = CryptoJS.AES.decrypt(procesar, key);
			let salida = decifrado.toString(CryptoJS.enc.Utf8);

			function extraerNumeroIf(string) {
				const array = string.split('&');
				const elementoIf = array.find(elemento => elemento.startsWith('if='));
				const numeroIf = elementoIf ? elementoIf.substring(3) : null;
				return numeroIf;
			}
			const string = salida;
			const numeroIf = extraerNumeroIf(string);
			setUser(numeroIf);
		}
	}, [valorEncriptado]);

	useEffect(() => {
		setRowsObserveFilter(rowsObserve);
	}, [rowsObserve]);

	useEffect(() => {
		handleIdsToDelete();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected]);

	// useEffect(() => {
	// 	const ultimoFirmanteCircuito = rowsPending.filter(
	// 		row => row.firmasTotal - row.firmasRealizadas === 1
	// 	);
	// 	setCircuitRows(ultimoFirmanteCircuito);
	// }, [rowsPending]);

	const handleIdsToDelete = () => {
		const newDocs = [];
		const newFolders = [];
		const idInFolders = [];
		selected.forEach(id => {
			const documento = rows.find(doc => doc.id === id);
			if (documento) {
				if (documento.isCircuit) {
					if (documento.documentCircuito) {
						if (!newFolders.includes(id)) {
							newFolders.push(id);
						}
					} else {
						idInFolders.push(id);
						if (!newDocs.includes(documento.idCircuito))
							newFolders.push(documento.idCircuito);
					}
				} else {
					newDocs.push(id);
				}
			} else {
				const foundMatch = rows.find(
					row =>
						row.documentCircuito !== null &&
						row.documentCircuito.find(file => file.id === id)
				);
				if (foundMatch) {
					idInFolders.push(id);
					if (!newFolders.includes(foundMatch.id)) {
						newFolders.push(foundMatch.id);
					}
				}
			}
		});
		setIdDocsSelected(newDocs);
		setIdCarpetCircuit(newFolders);
		setIdsInFolder(idInFolders);
	};
	useEffect(() => {
		if (tabSelected === 0) {
			(async () => {
				setLoading(true);
				await getListDocumentCF(userInfo.email, true);
				setLoading(false);
			})();
		}
	}, []);

	useEffect(() => {
		if (!isHome) {
			(async () => {
			
				switch (tabSelected) {
					case 0:
						setLoading(true);
						await getListDocumentCF(userInfo.email, true);
						setLoading(false);
						const totalDocumentosPendientes = await dispatch(
							getListDocumentPending(true)
						);
					
						const totalDocumentos = await dispatch(
							getListDocumentPendingObserve(true)
						);
						
						setTotalDocumentosObservar(totalDocumentos);
						setTotalDocumentosPendientes(totalDocumentosPendientes)

						break;

					case 1:
						await dispatch(getListDocumentPending(true));
						break;

					case 2:
						await dispatch(getListDocumentPending(true));
						break;

					case 3:
						await dispatch(getListDocumentPendingObserve(true));
						break;

					default:
						break;
				}
			})();
		}
	}, [tabSelected]);

	useEffect(() => {
		setSelected([]);
		setRowsFilter(rows);
		setRowsMassiveFilter(rowsPending);
		setRowsPendingFilter(rowsPending);
		setPage(0);
		document.getElementById('txtBuscar').value = '';
		document.body.className = 'bodyMiddle';
		if (
			(tabSelected === 0 && rows.length < 1) ||
			(tabSelected === 1 && rowsPending.length < 1) ||
			(tabSelected === 2 && rowsPending.length < 1)
		) {
			setIsFilterIsNull(true);
		} else {
			setIsFilterIsNull(false);
		}
	}, [rows, rows.length, tabSelected, rowsPending.length]);

	useEffect(() => {
		const isAllCarpetSelected = filteredIds.every(id => selected.includes(id));
		if (!isAllCarpetSelected) {
			setDisabledBtnDelete(true);
		} else {
			setDisabledBtnDelete(false);
		}
	}, [selected, filteredIds, idCarpetCircuit]);

	const pendingsLength = () => {
		if (tabSelected === 0 && !firstTime) {
			getListDocumentCF(userInfo.email, true, true);
			setFisrtTime(true);
		}
	};

	function obtenerEncriptadoPorId(idBuscado) {
		const encript = rowsPending.find(usuario => usuario.id === idBuscado);
		return encript ? encript.encriptado : null;
	}

	const loadDocuments = () => {
		(async () => {
			setLoading(true);

			switch (tabSelected) {
				case 0: // Caso para tabSelected = 0
					await getListDocumentCF(userInfo.email, false);
					break;

				case 1: // Caso para tabSelected = 1
					await dispatch(getListDocumentPending(false));
					break;

				case 2: // Caso para tabSelected = 2
					// Aquí podrías llamar a otra acción o realizar otra lógica
					await dispatch(getListDocumentPending(false));
					break;

				case 3: // Caso para tabSelected = 3
					// Aquí podrías llamar a otra acción o realizar otra lógica
					await dispatch(getListDocumentPendingObserve(false));
					break;

				default:
					console.log('Tab seleccionado no válido');
					break;
			}

			setLoading(false);
		})();
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};
	const handleSelectAllClick = (event, ListOrder) => {
		if (selected.length > 0) {
			setAllSelected(false);
			setSelected([]);
			setIdsFolder([]);
			setOpenedRowIndex(-1);
		} else if (isAllSelected == false || selected.length == 0) {
			const listRows = [];
			const ListsIdsFolder = [];
			const idFolderSelect = [];
			let count = p7z;
			let cont = 0;
			const rowsToSelect = tabSelected == 0 ? rows : rowsPending;
			const prueba = ListOrder;
			ListOrder.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(
				(row, index) => {
					if (index < 10) {
						if (row.isFolder) {
							const idsCircuito = row.documentCircuito.map(
								document => document.id
							);
							listRows.push(row);
							ListsIdsFolder.push({
								idFolder: row.id,
								idsCircuit: idsCircuito,
							});
						} else {
							listRows.push(row);
						}
						let nombre = row.nombrereal;
						let extension = nombre.split('.').pop();
						if (extension != 'pdf') {
							count += 1;
						}
					}
				}
			);
			if (event.target !== undefined) {
				if (event.target.checked && selected.length < MAX_FILE_DOWNLOAD) {
					const newSelecteds = [];
					if (newSelecteds.length - cont < MAX_FILE_DOWNLOAD) {
						for (let i = 0; i < prueba.length; i++) {
							if (newSelecteds.length - cont < MAX_FILE_DOWNLOAD) {
								let indexObject = listRows.find(row => row.id === prueba[i].id);
								if (indexObject?.isFolder) {
									const idFolder = ListsIdsFolder.filter(
										obj => obj.idFolder === indexObject.id
									);
									const folder = idFolder.map(obj => obj.idsCircuit);
									folder[0].forEach(id => {
										if (newSelecteds.length - cont < MAX_FILE_DOWNLOAD) {
											newSelecteds.push(id);
										}
									});
									const includes = folder.some(arr =>
										arr.every(value => newSelecteds.includes(value))
									);
									if (includes) {
										newSelecteds.push(prueba[i].id);
										idFolderSelect.push(prueba[i].id);
										setIdsFolder(idFolderSelect);
										cont++;
									} else {
										setOpenedRowIndex(i);
									}
								} else {
									if (newSelecteds.length - cont < MAX_FILE_DOWNLOAD) {
										if (indexObject.id === prueba[i].id) {
											newSelecteds.push(prueba[i].id);
										}
										let nombre = prueba[i].nombrereal;
										let extension = nombre.split('.').pop();
										if (extension != 'pdf') {
											count += 1;
										}
									}
								}
							}
						}
					}
					setP7z(count);
					setSelected(newSelecteds);
				}
			}
			setAllSelected(true);
		} else {
			setAllSelected(false);
			setSelected([]);
		}
	};
	const idsCarpetMassive = (id, files) => {
		let searchFiles = files.filter(file => file.id === id);

		if (searchFiles.length > 0) {
			const file = searchFiles[0];

			// Caso en el que el archivo es una carpeta con documentos
			if (file.documentos && Array.isArray(file.documentos)) {
				// Filtrar los IDs de los documentos que pertenecen a esta carpeta
				let documentIds = file.documentos.map(doc => doc.id);

				return documentIds;
			} else {
				// Caso en el que el archivo es un documento individual o no hay documentos
				return [];
			}
		} else {
			// En caso de que no se encuentre ningún archivo con ese ID
			return [];
		}
	};

	const isIdCarpet = (id, files) => {
		let searchFiles = files.filter(file => file.id === id);

		if (searchFiles.length > 0) {
			const file = searchFiles[0];

			// Caso en el que el archivo es una carpeta con documentos
			if (file.documentCircuito) {
				// Actualizar idsFolder
				setIdsFolder(idsFolder =>
					idsFolder.includes(id)
						? idsFolder.filter(ob => ob !== id)
						: [...idsFolder, id]
				);

				// Filtrar los documentos que pertenecen a esta carpeta
				let documentIds = file.documentCircuito
					.filter(doc => doc.idCircuito === id)
					.map(doc => doc.id);

				// Incluir el ID de la carpeta en la lista de IDs
				return [...documentIds, id];
			} else {
				// Caso en el que el archivo es un documento individual
				return [id];
			}
		} else {
			// En caso de que no se encuentre ningún archivo con ese ID
			return [];
		}
	};

	const checkFolder = (id, rows, selected, cont) => {
		let newSelected = [];
		const foundMatch = rows.find(
			row =>
				row.documentCircuito !== null &&
				row.documentCircuito.find(file => file.id === id)
		);

		if (foundMatch) {
			const arrayIds = foundMatch.documentCircuito.map(ob => ob.id);
			const result = arrayIds.every(val => selected.includes(val));
			if (result) {
				setIdsFolder([...idsFolder, foundMatch.id]);
				return newSelected.concat(selected, foundMatch.id);
			} else {
				setIdsFolder(idsFolder.filter(ob => ob !== foundMatch.id));
				return selected;
			}
		}
		return selected;
	};
	const checkFolderMasive = (id, rows, selected, cont) => {
		let newSelected = [];
		const foundMatch = rows.find(
			row => row.documentos !== null && row.id === id
		);

		if (foundMatch) {
			const arrayIds = foundMatch.documentos.map(ob => ob.id);
			const result = arrayIds.every(val => selected.includes(val));

			if (result) {
				setIdsFolder([...idsFolder, foundMatch.id]);
				// Asegurarse de que solo se añaden IDs de carpetas a 'newSelected'
				newSelected = selected.filter(val => !arrayIds.includes(val));
				return newSelected.concat(foundMatch.id);
			} else {
				setIdsFolder(idsFolder.filter(ob => ob !== foundMatch.id));
				// Eliminar ID de carpeta si no todos los documentos están seleccionados
				return selected.filter(val => val !== foundMatch.id);
			}
		}
		return selected;
	};

	const findInRows = (id, rows) => {
		const foundMatch = rows.find(
			row =>
				row.documentCircuito !== null &&
				row.documentCircuito.find(file => file.id === id)
		);

		if (foundMatch) setIdsFolder(idsFolder.filter(ob => ob !== foundMatch.id));

		return foundMatch ? foundMatch.id : null;
	};

	const skipIdFolders = (ids, selected, folderId, rows) => {
		if (typeof ids === 'number') {
			ids = [ids];
		} else {
			const foundMatch = rows.find(
				row =>
					row.documentCircuito !== null &&
					row.documentCircuito.find(file => ids.includes(file.idCircuito))
			);
			if (foundMatch) ids = ids.filter(ob => ob !== foundMatch.id);
		}
		const contselected = selected.length;
		const contids = ids.length;
		const contfolder = folderId.length;
		const cont = contselected + contids - contfolder;

		return cont;
	};

	const handleClickMassive = (event, name) => {
		const row = event.target.closest('tr');
		const isrowselected = selected.includes(name);
		let newSelected = [];

		if (event.target.alt == 'eliminar') {
			handleClickDelete(name);
		} else if (
			event.target.checked === true ||
			(row.id == name && !isrowselected)
		) {
			const idsSelected = idsCarpetMassive(name, rowsPending);
			const totalDocsInSelection =
				selected.length > 0
					? getTotalDocsInSelectedFolders(selected, rowsPending) +
					  idsSelected.length
					: idsSelected.length;

			if (totalDocsInSelection <= MAX_FILE_DOWNLOAD) {
				newSelected = newSelected.concat(selected, idsSelected);
				newSelected = checkFolderMasive(name, rowsPending, newSelected);
			} else {
				enqueueSnackbar(
					`Máximo puede seleccionar ${MAX_FILE_DOWNLOAD} documentos`,
					{
						variant: 'warning',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					}
				);
				return;
			}
			setSelected(newSelected);
		} else {
			const idsToDelete = isIdCarpet(name, rowsPending);

			if (typeof idsToDelete === 'number') {
				newSelected = selected.filter(x => x !== idsToDelete);
				const rowFound = findInRows(name, rows);
				if (rowFound) {
					newSelected = newSelected.filter(x => x !== rowFound);
				}
			} else {
				newSelected = selected.filter(id => !idsToDelete.includes(id));
			}
			setSelected(newSelected);
		}
		let count = 0;

		newSelected.map(sel => {
			let file = rows.find(x => x.id === sel);
			let extension = file ? file.nombrereal.split('.').pop() : '';
			if (extension != 'pdf') {
				count += 1;
			}
		});
		setP7z(count);
	};
	const getTotalDocsInSelectedFolders = (selected, rowsPending) => {
		let totalDocs = 0;
		selected.forEach(selectedId => {
			const folder = rowsPending.find(row => row.id === selectedId);
			if (folder && folder.documentos) {
				totalDocs += folder.documentos.length;
			}
		});
		return totalDocs;
	};

	const handleClick = async (event, name) => {
		const row = event.target.closest('tr');
		const isrowselected = selected.includes(name);
		let newSelected = [];

		if (event.target.alt == 'eliminar') {
			handleClickDelete(name);
		} else if (
			event.target.checked === true ||
			(row.id == name && !isrowselected)
		) {
			const idsSelected = isIdCarpet(name, rows);
			const idsWithoutFolder = skipIdFolders(
				idsSelected,
				selected,
				idsFolder,
				rows
			);

			if (idsWithoutFolder <= MAX_FILE_DOWNLOAD) {
				newSelected = newSelected.concat(selected, idsSelected);
				newSelected = checkFolder(idsSelected, rows, newSelected);
			} else {
				enqueueSnackbar(
					`Máximo puede seleccionar ${MAX_FILE_DOWNLOAD} documentos`,
					{
						variant: 'warning',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					}
				);
				return;
			}
			setSelected(newSelected);
		} else {
			const idsToDelete = isIdCarpet(name, rows);

			if (typeof idsToDelete === 'number') {
				newSelected = selected.filter(x => x !== idsToDelete);
				const rowFound = findInRows(name, rows);
				if (rowFound) {
					newSelected = newSelected.filter(x => x !== rowFound);
				}
			} else {
				newSelected = selected.filter(id => !idsToDelete.includes(id));
			}
			setSelected(newSelected);
		}
		let count = 0;

		newSelected.map(sel => {
			let file = rows.find(x => x.id === sel);
			let extension = file ? file.nombrereal.split('.').pop() : '';
			if (extension != 'pdf') {
				count += 1;
			}
		});
		setP7z(count);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = event => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const getDate = string => {
		var date = new Date(string);
		if (date != null)
			return (
				date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
			);
	};

	const excludeColumns = [
		'tipoFirma',
		'ruta',
		'repositorio',
		'peso',
		'origenFirma',
		'nombrerepositorio',
		'id',
		'consecutivo',
	];

	const backToSignOneFile = (e, row) => {
		e.stopPropagation();
		setStatusModalAvanzado(true);
		setIdOneFile(row);
	};

	const isSelected = name => selected.indexOf(name) !== -1;

	const handleChangeBuscar = useCallback(
		({ target }) => {
			const { value: searchValue } = target;
			setValueSearch(searchValue);
			const lowercasedValue = searchValue.toLowerCase().trim();

			if (lowercasedValue.length <= 3 && lowercasedValue !== '') {
				return;
			}

			if (lowercasedValue === '') {
				tabSelected === 0
					? setRowsFilter(rows)
					: tabSelected === 1
					? setRowsPendingFilter(rowsPending)
					: setRowsMassiveFilter(rowsPending);
				setIsFilterIsNull(false);
				return;
			}
			setPage(0);

			const rowsSearch =
				tabSelected === 0
					? rows
					: tabSelected === 1
					? rowsPending
					: rowsPending;

			const filteredData = rowsSearch.filter(item => {
				return Object.keys(item).some(key =>
					['fechaRecepcion', 'fechaCreacion', 'fechaFirma'].includes(key)
						? getDate(item[key]).includes(lowercasedValue)
						: ['descripcionOrigenFirma', 'nombrereal'].includes(key)
						? item[key].toString().toLowerCase().includes(lowercasedValue)
						: ''
				);
			});

			setIsFilterIsNull(filteredData.length === 0);
			tabSelected === 0
				? setRowsFilter(filteredData)
				: tabSelected === 1
				? setRowsPendingFilter(filteredData)
				: setRowsMassiveFilter(filteredData);
		},
		[
			rows,
			rowsFilter,
			tabSelected,
			rowsPendingFilter,
			rowsPending,
			valueSearch,
			rowsPending,
			rowsMassiveFilter,
		]
	);

	const onClose = type => {
		refresh(type);
		setStatusModalAvanzado(false);
	};

const onClosePop = async () => {
	dispatch({
		type: POP,
		payload: 0,
	});

	window.location.reload();
};



	const refresh = reInitSelect => {
		if (reInitSelect) {
			setSelected([]);
			setPage(0);
			ref.current.scrollTo(0, 0);
			getListDocumentCF(userInfo.email, true);
		}
	};

	const handleClickDelete = async e => {
		setLoading(true);
		let eventDelete = [e];
		await deleteFile(eventDelete, enqueueSnackbar);
		setSelected([]);
		setPage(0);
		getListDocumentCF(userInfo.email);
		setIndex(null);
		setLoading(false);
	};

	const handleChange = (event, newValue) => {
		setValue(newValue);
		setOpenedRowIndex(-1);
		setTabSelected(newValue);
	};

	const sort = (orderby, order) => {
		setOrderBy(orderby);
		setOrder(order);
		setOrderMenu(null);
	};

	const dateTypeFields = ['fechaFirma'];

	const ListOrder = useMemo(() => {
		return orderListype(rowsFilter, orderBy, order, dateTypeFields);
	}, [rowsFilter, orderBy, order]);
	const dateTypeFieldsm = ['fechaRecepcion', 'fechaCreacion'];

	const ListOrderMasivo = useMemo(() => {
		return orderListypeMasivo(
			rowsMassiveFilter,
			orderBym,
			order,
			dateTypeFieldsm
		);
	}, [rowsMassiveFilter, orderBy, order]);

	return (
		<>
			<Grid container spacing={0} direction='row'>
				{!isHome && (
					<Container component='main' maxWidth='lg'>
						<h1 className={classes.title}>Mis documentos</h1>
					</Container>
				)}
				<Container component='main'>
					<Paper
						elevation={3}
						className={'mainContentPaperDocuments'}
						style={paperStyles}
					>
						{isHome && (
							<>
								<Typography className={'titleDocumentsHome'}>
									Documentos pendientes por firmar
								</Typography>
								<p id='texi2' className={'subtitleDocumentsHome'}>
									{/* <b>"Podemos convertir lo difícil en algo fácil"</b> */}
									<br></br>
									<b>
										Selecciona varios documentos para aplicar una firma masiva.
									</b>
								</p>
							</>
						)}

						<div
							className={'searchBar'}
							style={{
								display: isHome ? 'none' : 'block',
							}}
						>
							<TextField
								className={'fieldSearch'}
								id='txtBuscar'
								onChange={handleChangeBuscar}
								style={{ marginLeft: '7px', width: '64%' }}
							/>
						</div>

						<Tabs
							value={value}
							onChange={handleChange}
							indicatorColor='primary'
							textColor='primary'
							className={classes.tabs}
							style={{
								display: isHome ? 'none' : 'block',
							}}
							variant='scrollable'
							scrollButtons='auto'
						>
							{/* Solo oculta las pestañas pero no las elimina para que los tabs sigan funcionando */}
							{!isHome && (
								<Tab className={classes.seleccion} label={`Completados`} />
							)}
							{!isHome && (
								<Tab
									className={classes.seleccion}
									label={`Pendientes por Firmar: ${
										totalDocumentosPendientes ? totalDocumentosPendientes : ' '
									}`}
								/>
							)}
							{!isHome && (
								<Tab
									className={classes.seleccion}
									label={
										isHome
											? `Pendientes por Firmar: ${
													totalDocumentosPendientes
														? totalDocumentosPendientes
														: ' '
											  }`
											: `Firma Masiva : ${
													totalDocumentosPendientes
														? totalDocumentosPendientes
														: ' '
											  }`
									}
								/>
							)}
							{!isHome && (
								<Tab
									className={classes.seleccion}
									label={`Por Observar: ${
										totalDocumentosObservar ? totalDocumentosObservar : ' '
									}`}
								/>
							)}
						</Tabs>
						{!isHome && (
							<hr
								style={{
									padding: 0,
									margin: '-7px 25px 0',
									height: '3px',
									backgroundColor: '#C1C1C1',
								}}
							/>
						)}
						<Button
							onClick={e => setOrderMenu(e.currentTarget)}
							className={classes.ordenar}
							endIcon={<ArrowDropDownIcon />}
						>
							Ordenar por
						</Button>
						<div className={'contentDocumentSignedHome'}>
							<EnhancedTableToolbar
								numSelected={selected.length}
								rowCount={rowsFilter.length}
								selected={
									tabSelected === 0 || tabSelected === 1
										? [...idDocsSelected, ...idsInFolder]
										: selected
								}
								handleSelectAllClick={e => handleSelectAllClick(e, ListOrder)}
								setSelected={setSelected}
								isP7={p7z}
								refresh={refresh}
								isFilterIsNull={isFilterIsNull}
								tabSelected={tabSelected}
								setTabSelected={setTabSelected}
								setModalDeleteFiles={setModalDeleteFiles}
								idDocsSelected={idDocsSelected}
								idCarpetCircuit={idCarpetCircuit}
								disabledBtnDelete={disabledBtnDelete}
								setOpenedRowIndex={setOpenedRowIndex}
								rows={tabSelected === 0 ? rowsFilter : rowsPending}
								user={user}
								url={
									selected.length === 1
										? obtenerEncriptadoPorId(selected[0])
										: null
								}
							/>

							{tabSelected === 0 && (
								<>
									{' '}
									<div ref={ref} className={'containerTabSignedDocument'}>
										<TableContainer
											style={{
												minWidth: '868px',
											}}
										>
											<Table
												className={'tableSignedDocuments'}
												aria-labelledby='tableTitle'
												size='medium'
												aria-label='enhanced table'
												cellSpacing='0px'
											>
												<EnhancedTableHead
													classes={classes}
													numSelected={selected.length}
													order={order}
													orderBy={orderBy}
													onSelectAllClick={handleSelectAllClick}
													onRequestSort={handleRequestSort}
													rowCount={rowsFilter.length}
													value={value}
												/>
												{!isFilterIsNull ? (
													<TableBody>
														{ListOrder.slice(
															page * rowsPerPage,
															page * rowsPerPage + rowsPerPage
														).map((row, index) => {
															const isItemSelected = isSelected(row.id);
															const labelId = `enhanced-table-checkbox-${index}`;
															return !row.isFolder ? (
																<TableRow
																	hover
																	onClick={
																		isFilterIsNull
																			? null
																			: event => handleClick(event, row.id)
																	}
																	role='checkbox'
																	aria-checked={isItemSelected}
																	tabIndex={-1}
																	key={row.id}
																	selected={isItemSelected}
																	style={{ cursor: 'pointer' }}
																	onMouseEnter={() => setIndex(row.id)}
																	onMouseLeave={() => setIndex(null)}
																	className={classes.fila}
																	id={row.id}
																>
																	<TableCell padding='none'>
																		<StyledCheckbox
																			checked={isItemSelected}
																			className={'checkButtonDocumentSigned'}
																			inputProps={{
																				'aria-labelledby': labelId,
																			}}
																		/>
																	</TableCell>
																	<TableCell
																		align='left'
																		component='th'
																		scope='row'
																		padding='normal'
																	>
																		{isFilterIsNull ? (
																			<Grid container>
																				<Grid item xs={1} />
																				<Grid item xs={11}>
																					<Typography>
																						{row.nombrereal}
																					</Typography>
																				</Grid>
																			</Grid>
																		) : (
																			<Grid container>
																				<Grid item xs={1} />
																				<Grid item xs={11}>
																					<Typography
																						className={classes.nombre}
																					>
																						{cutName(row.nombrereal)}
																					</Typography>
																				</Grid>
																			</Grid>
																		)}
																	</TableCell>
																	<TableCell align='start' padding='none'>
																		<Typography className={classes.origen}>
																			{orderCertificate(
																				row.descripcionOrigenFirma
																			)}
																		</Typography>
																	</TableCell>{' '}
																	<TableCell align='start' padding='none'>
																		<DateSign
																			fechaFirma={row.fechaFirma}
																			index={index}
																			indexRow={indexRow}
																		/>
																	</TableCell>
																	<TableCell
																		align={
																			window.innerWidth < 1265
																				? 'center'
																				: 'start'
																		}
																		padding='none'
																	>
																		<Resign
																			index={row.id}
																			indexRow={indexRow}
																			isCircuit={row.isCircuit}
																			selected={selected}
																			theme={theme}
																			backToSignOneFile={backToSignOneFile}
																		/>
																	</TableCell>
																</TableRow>
															) : (
																<>
																	<TableRow
																		hover
																		id={row.id}
																		onClick={
																			isFilterIsNull
																				? null
																				: event => handleClick(event, row.id)
																		}
																		role='checkbox'
																		aria-checked={isItemSelected}
																		tabIndex={-1}
																		key={row.id}
																		selected={isItemSelected}
																		style={{
																			cursor: 'pointer',
																		}}
																		onMouseEnter={() => setIndex(row.id)}
																		onMouseLeave={() => setIndex(null)}
																		className={
																			openedRowIndex === index
																				? classes.filaFolder
																				: classes.fila
																		}
																	>
																		<TableCell padding='none'>
																			<StyledCheckbox
																				checked={isItemSelected}
																				className={'checkButtonDocumentSigned'}
																				inputProps={{
																					'aria-labelledby': labelId,
																				}}
																			/>
																		</TableCell>
																		<TableCell
																			align='left'
																			component='th'
																			id={labelId}
																			scope='row'
																			padding='normal'
																			// onClick={
																			// 	isFilterIsNull
																			// 		? null
																			// 		: event =>
																			// 				handleClickview(event, row.id)
																			// }
																		>
																			{isFilterIsNull ? (
																				<Grid container>
																					<Grid item xs={1}>
																						<FolderIcon />
																					</Grid>
																					<Grid item xs={11}>
																						<Typography>
																							{row.nombrereal}
																						</Typography>
																					</Grid>
																				</Grid>
																			) : (
																				<Grid container>
																					<Grid item xs={1}>
																						<FolderIcon
																							style={{
																								color: '#878787',
																								width: '16px',
																								height: '16px',
																							}}
																						/>
																					</Grid>
																					<Grid item xs={11}>
																						<Typography
																							className={classes.nombre}
																						>
																							{cutName(row.nombrereal)}
																						</Typography>
																					</Grid>
																				</Grid>
																			)}
																		</TableCell>
																		<TableCell align='start' padding='none'>
																			<Typography className={classes.origen}>
																				{orderCertificate(
																					row.descripcionOrigenFirma
																				)}
																			</Typography>
																		</TableCell>{' '}
																		<TableCell align='start' padding='none'>
																			<DateSign
																				fechaFirma={row.fechaFirma}
																				index={index}
																				indexRow={indexRow}
																			/>
																		</TableCell>
																		<TableCell
																			align='start'
																			padding='none'
																			flexDirection='row'
																		>
																			<div
																				style={{
																					display: 'flex',
																					justifyContent: 'space-between',
																					alignItems: 'center',
																					width: '100%',
																				}}
																			>
																				<Button className='btnChange2'>
																					Circuito de firmas
																				</Button>
																				<div
																					style={{
																						display: 'flex',
																						justifyContent: 'center',
																						alignItems: 'center',
																						flexGrow: 1, 
																						gap: '1px',
																					}}
																					onClick={e => {
																						e.stopPropagation();
																						setOpenedRowIndex(
																							openedRowIndex === index
																								? // abierto
																								  -1
																								: // cerrado
																								  index
																						);
																					}}
																				>
																					<span
																						className={classes.origen}
																						style={{ color: '#E55200' }}
																					>
																						{openedRowIndex === index
																							? 'Ocultar documentos'
																							: 'Ver documentos'}
																					</span>
																					<IconButton
																						aria-label='expand row'
																						size='small'
																						style={{ color: '#E55200' }}
																					>
																						{openedRowIndex === index ? (
																							<KeyboardArrowUpIcon />
																						) : (
																							<KeyboardArrowDownIcon />
																						)}
																					</IconButton>
																				</div>
																			</div>
																		</TableCell>
																	</TableRow>

																	<TableRow>
																		<TableCell
																			style={{
																				paddingBottom: 0,
																				paddingTop: 0,
																			}}
																			colSpan={6}
																		>
																			<Collapse
																				in={openedRowIndex === index}
																				timeout='auto'
																				unmountOnExit
																			>
																				<Box margin={1}>
																					<Table
																						size='small'
																						aria-label='purchases'
																					>
																						<TableHead>
																							<TableRow
																								component={Paper}
																								style={{
																									border: 'none',
																									boxShadow: 'none',
																								}}
																							>
																								<TableCell
																									width='10%'
																									align='end'
																									padding='none'
																								/>
																								<TableCell
																									width='34%'
																									align='start'
																									className={
																										classes.titleEncabezado
																									}
																								>
																									Documento
																								</TableCell>
																								<TableCell
																									width='25%'
																									align='start'
																									padding='normal'
																									className={
																										classes.titleEncabezado
																									}
																								>
																									Certificado
																								</TableCell>
																								<TableCell
																									padding='normal'
																									width='19%'
																									align='start'
																									className={
																										classes.titleEncabezado
																									}
																								>
																									Fecha de firma
																								</TableCell>
																								<TableCell
																									padding='none'
																									width='15%'
																									align='start'
																									className={
																										classes.titleEncabezado
																									}
																								/>
																							</TableRow>
																						</TableHead>
																						<TableBody>
																							{row.documentCircuito.map(
																								(row, index) => {
																									const isItemSelected =
																										isSelected(row.id);
																									const labelId = `enhanced-table-checkbox-${index}`;
																									return (
																										<TableRow
																											hover
																											id={row.id}
																											onClick={
																												isFilterIsNull
																													? null
																													: event =>
																															handleClick(
																																event,
																																row.id
																															)
																											}
																											role='checkbox'
																											aria-checked={
																												isItemSelected
																											}
																											tabIndex={-1}
																											key={row.id}
																											selected={isItemSelected}
																											style={{
																												cursor: 'pointer',
																											}}
																											// onMouseEnter={() =>
																											// 	setIndex(row.id)
																											// }
																											// onMouseLeave={() =>
																											// 	setIndex(null)
																											// }
																											className={classes.fila}
																											// className={`${classes.fila} ${classes.noVisible}`}
																										>
																											<TableCell
																												padding='checkbox'
																												style={{
																													textAlign: 'end',
																												}}
																											>
																												{' '}
																												{isItemSelected && (
																													<StyledCheckbox
																														checked={
																															isItemSelected
																														}
																														className={
																															'checkButtonDocumentSigned'
																														}
																														inputProps={{
																															'aria-labelledby':
																																labelId,
																														}}
																													/>
																												)}
																											</TableCell>
																											<TableCell
																												align='start'
																												component='th'
																												id={labelId}
																												scope='row'
																												padding='normal'
																												// onClick={
																												// 	isFilterIsNull
																												// 		? null
																												// 		: event =>
																												// 				handleClickview(
																												// 					event,
																												// 					row.id
																												// 				)
																												// }
																											>
																												{isFilterIsNull ? (
																													<Typography>
																														{row.nombrereal}
																													</Typography>
																												) : (
																													<Typography
																														className={
																															classes.nombre
																														}
																													>
																														{cutName(
																															row.nombrereal
																														)}
																													</Typography>
																												)}
																											</TableCell>
																											<TableCell
																												align='start'
																												padding='normal'
																											>
																												<Typography
																													className={
																														classes.origen
																													}
																												>
																													{orderCertificate(
																														row.descripcionOrigenFirma
																													)}
																												</Typography>
																											</TableCell>{' '}
																											<TableCell
																												align='start'
																												padding='normal'
																											>
																												<DateSign
																													fechaFirma={
																														row?.fechaFirma
																													}
																													index={index}
																													indexRow={indexRow}
																												/>
																											</TableCell>
																											<TableCell
																												align='start'
																												padding='none'
																											>
																												<Resign
																													index={row.id}
																													indexRow={indexRow}
																													isCircuit={
																														row?.isCircuit
																													}
																													selected={selected}
																													isFolder={true}
																													backToSignOneFile={
																														backToSignOneFile
																													}
																												/>
																											</TableCell>
																										</TableRow>
																									);
																								}
																							)}
																						</TableBody>
																					</Table>
																				</Box>
																			</Collapse>
																		</TableCell>
																	</TableRow>
																</>
															);
														})}
													</TableBody>
												) : (
													<TableBody>
														<TableCell />
														<TableCell>No se encontraron Documentos</TableCell>
														<TableCell />
														<TableCell />
														<TableCell />
														<TableCell />
													</TableBody>
												)}
											</Table>
										</TableContainer>
									</div>
									<Box
										display='flex'
										justifyContent='space-between'
										alignItems='center'
										width='100%'
										sx={{
											flexDirection: { xs: 'column', sm: 'row' }, // xs para móviles, sm para pantallas más grandes
										}}
									>
										<Box
											component='span'
											onClick={() => {
												loadDocuments();
											}}
											sx={{
												cursor: 'pointer',
												color: '#181C18',
												marginLeft: { xs: 0, sm: 10 },
												fontSize: '0.87rem',
												textDecoration: 'none',
												'&:hover': {
													color: '#E5682A',
												},
											}}
										>
											Ver más
										</Box>
										<TablePagination
											rowsPerPageOptions={[5, 10, 25]}
											component='div'
											count={rowsFilter.length}
											rowsPerPage={rowsPerPage}
											page={page}
											onChangePage={handleChangePage}
											labelRowsPerPage='Filas por página'
											onChangeRowsPerPage={handleChangeRowsPerPage}
										/>
									</Box>
									<Menu
										anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
										transformOrigin={{ vertical: 'top', horizontal: 'center' }}
										open={Boolean(orderMenu)}
										anchorEl={orderMenu}
										onClose={() => setOrderMenu(null)}
										getContentAnchorEl={null}
									>
										<MenuItem onClick={() => sort('nombrereal', 'asc')}>
											A - Z
										</MenuItem>
										<MenuItem onClick={() => sort('nombrereal', 'desc')}>
											Z - A
										</MenuItem>
										<MenuItem
											onClick={() =>
												sort(
													tabSelected === 0 ? 'fechaFirma' : 'fechaCreacion',
													'desc'
												)
											}
										>
											Más recientes
										</MenuItem>
										<MenuItem
											onClick={() =>
												sort(
													tabSelected === 0 ? 'fechaFirma' : 'fechaCreacion',
													'asc'
												)
											}
										>
											Más antiguos
										</MenuItem>
									</Menu>{' '}
								</>
							)}
							{tabSelected === 1 && (
								<PendingToSign
									classes={classes}
									cutName={cutName}
									DateSign={DateSign}
									handleClick={handleClick}
									handleSelectAllClick={handleSelectAllClick}
									indexRow={indexRow}
									isFilterIsNull={isFilterIsNull}
									ref={ref}
									rowsFilter={rowsPendingFilter}
									selected={selected}
									setIndex={setIndex}
									StyledCheckbox={StyledCheckbox}
									orderMenu={orderMenu}
									setOrderMenu={setOrderMenu}
									loadDocuments={loadDocuments}
								/>
							)}
							{tabSelected === 2 && (
								<>
									{' '}
									<div ref={ref} className={'containerTabSignedDocument'}>
										<TableContainer
											style={{
												minWidth: isHome ? 'auto' : '868px',
											}}
										>
											<Table
												className={'tableSignedDocuments'}
												aria-labelledby='tableTitle'
												size='medium'
												aria-label='enhanced table'
												cellSpacing='0px'
											>
												<EnhancedTableHead
													classes={classes}
													numSelected={selected.length}
													order={order}
													orderBy={orderBy}
													onSelectAllClick={handleSelectAllClick}
													onRequestSort={handleRequestSort}
													rowCount={rowsFilter.length}
													value={value}
												/>
												{!isFilterIsNull ? (
													<TableBody>
														{ListOrderMasivo.slice(
															page * rowsPerPage,
															page * rowsPerPage + rowsPerPage
														).map((row, index) => {
															const isItemSelected = isSelected(row.id);
															const labelId = `enhanced-table-checkbox-${index}`;
															return !row.isFolder ? (
																<TableRow
																	hover
																	onClick={
																		isFilterIsNull
																			? null
																			: event =>
																					handleClickMassive(event, row.id)
																	}
																	role='checkbox'
																	aria-checked={isItemSelected}
																	tabIndex={-1}
																	key={row.id}
																	selected={isItemSelected}
																	style={{ cursor: 'pointer' }}
																	onMouseEnter={() => setIndex(row.id)}
																	onMouseLeave={() => setIndex(null)}
																	className={classes.fila}
																	id={row.id}
																>
																	<TableCell padding='none'>
																		<StyledCheckbox
																			checked={isItemSelected}
																			className={'checkButtonDocumentSigned'}
																			inputProps={{
																				'aria-labelledby': labelId,
																			}}
																		/>
																	</TableCell>
																	<TableCell
																		align='left'
																		component='th'
																		scope='row'
																		padding='normal'
																	>
																		{isFilterIsNull ? (
																			<Grid container>
																				<Grid item xs={12}>
																					<Typography>
																						{row.nombrereal}
																					</Typography>
																				</Grid>
																			</Grid>
																		) : (
																			<Grid container>
																				<Grid item xs={12}>
																					<Typography
																						className={classes.nombre}
																					>
																						{cutName(row.nombrereal)}
																					</Typography>
																				</Grid>
																			</Grid>
																		)}
																	</TableCell>
																	{(!isHome || !isSmallScreen) && (
																		<>
																			<TableCell align='start' padding='none'>
																				<Typography className={classes.origen}>
																					{row.creador}
																				</Typography>
																			</TableCell>{' '}
																			<TableCell align='start' padding='none'>
																				<DateSign
																					fechaFirma={row.fechaCreacion}
																					index={index}
																					indexRow={indexRow}
																				/>
																			</TableCell>
																		</>
																	)}
																	<TableCell
																		align={
																			window.innerWidth < 1265
																				? 'center'
																				: 'start'
																		}
																		padding='none'
																	>
																		<ResignMassive
																			index={row.id}
																			indexRow={indexRow}
																			isCircuit={row.isCircuit}
																			selected={selected}
																			theme={theme}
																			backToSignOneFile={backToSignOneFile}
																		/>
																	</TableCell>
																</TableRow>
															) : (
																<>
																	<TableRow
																		hover
																		id={row.id}
																		onClick={
																			isFilterIsNull
																				? null
																				: event =>
																						handleClickMassive(event, row.id)
																		}
																		role='checkbox'
																		aria-checked={isItemSelected}
																		tabIndex={-1}
																		key={row.id}
																		selected={isItemSelected}
																		style={{
																			cursor: 'pointer',
																		}}
																		onMouseEnter={() => setIndex(row.id)}
																		onMouseLeave={() => setIndex(null)}
																		className={
																			openedRowIndex === index
																				? classes.filaFolder
																				: classes.fila
																		}
																	>
																		<TableCell padding='none'>
																			<StyledCheckbox
																				checked={isItemSelected}
																				className={'checkButtonDocumentSigned'}
																				inputProps={{
																					'aria-labelledby': labelId,
																				}}
																			/>
																		</TableCell>
																		<TableCell
																			align='left'
																			component='th'
																			id={labelId}
																			scope='row'
																			padding='normal'
																			// onClick={
																			// 	isFilterIsNull
																			// 		? null
																			// 		: event =>
																			// 				handleClickview(event, row.id)
																			// }
																		>
																			{isFilterIsNull ? (
																				<Grid container>
																					<Grid item xs={1}>
																						<FolderIcon />
																					</Grid>
																					<Grid item xs={11}>
																						<Typography>
																							{row.nombrereal}
																						</Typography>
																					</Grid>
																				</Grid>
																			) : (
																				<Grid container>
																					<Grid item xs={1}>
																						<FolderIcon
																							style={{
																								color: '#878787',
																								width: '16px',
																								height: '16px',
																							}}
																						/>
																					</Grid>
																					<Grid item xs={11}>
																						<Typography
																							className={classes.nombre}
																						>
																							{cutName(row.nombrereal)}
																						</Typography>
																					</Grid>
																				</Grid>
																			)}
																		</TableCell>
																		{(!isHome || !isSmallScreen) && (
																			<>
																				<TableCell align='start' padding='none'>
																					<Typography
																						className={classes.origen}
																					>
																						{row.creador}
																					</Typography>
																				</TableCell>{' '}
																				<TableCell align='start' padding='none'>
																					<DateSign
																						fechaFirma={row.fechaCreacion}
																						index={index}
																						indexRow={indexRow}
																					/>
																				</TableCell>
																				<TableCell align='start' padding='none'>
																					<div
																						style={{
																							display: 'flex',
																							justifyContent: 'center',
																							alignItems: 'center',
																							width: "100%",
																						}}
																						onClick={e => {
																							e.stopPropagation();
																							setOpenedRowIndex(
																								openedRowIndex === index
																									? // abierto
																									  -1
																									: //cerrado
																									  index
																							);
																						}}
																					>
																						<span
																							className={classes.origen}
																							style={{ color: '#E55200' }}
																						>
																							{openedRowIndex === index
																								? 'Ocultar documentos'
																								: 'Ver documentos'}
																						</span>
																						<IconButton
																							aria-label='expand row'
																							size='small'
																							style={{ color: '#E55200' }}
																						>
																							{openedRowIndex === index ? (
																								<KeyboardArrowUpIcon />
																							) : (
																								<KeyboardArrowDownIcon />
																							)}
																						</IconButton>
																					</div>
																				</TableCell>
																			</>
																		)}
																	</TableRow>

																	<TableRow>
																		<TableCell
																			style={{
																				paddingBottom: 0,
																				paddingTop: 0,
																			}}
																			colSpan={6}
																		>
																			<Collapse
																				in={openedRowIndex === index}
																				timeout='auto'
																				unmountOnExit
																			>
																				<Box margin={1}>
																					<Table
																						size='small'
																						aria-label='purchases'
																					>
																						<TableHead>
																							<TableRow
																								component={Paper}
																								style={{
																									border: 'none',
																									boxShadow: 'none',
																								}}
																							>
																								<TableCell
																									width='10%'
																									align='end'
																									padding='none'
																								/>
																								<TableCell
																									width='34%'
																									align='start'
																									className={
																										classes.titleEncabezado
																									}
																								>
																									Documento
																								</TableCell>
																								<TableCell
																									padding='none'
																									width='15%'
																									align='start'
																									className={
																										classes.titleEncabezado
																									}
																								/>
																							</TableRow>
																						</TableHead>
																						<TableBody>
																							{row.documentos.map(
																								(row, index) => {
																									return (
																										<TableRow
																											id={row.id}
																											tabIndex={-1}
																											key={row.id}
																											style={{
																												cursor: 'pointer',
																											}}
																										>
																											<TableCell
																												padding='checkbox'
																												style={{
																													textAlign: 'end',
																												}}
																											>
																												<StyledCheckbox
																													checked={
																														isItemSelected
																													}
																													className={
																														'checkButtonDocumentSigned'
																													}
																													inputProps={{
																														'aria-labelledby':
																															labelId,
																													}}
																												/>
																											</TableCell>
																											<TableCell
																												align='start'
																												component='th'
																												id={labelId}
																												scope='row'
																												padding='normal'
																												// onClick={
																												// 	isFilterIsNull
																												// 		? null
																												// 		: event =>
																												// 				handleClickview(
																												// 					event,
																												// 					row.id
																												// 				)
																												// }
																											>
																												{isFilterIsNull ? (
																													<Typography>
																														{row.nombre}
																													</Typography>
																												) : (
																													<Typography
																														className={
																															classes.nombre
																														}
																													>
																														{cutName(
																															row.nombre
																														)}
																													</Typography>
																												)}
																											</TableCell>
																											<TableCell
																												align='start'
																												padding='none'
																											>
																												<ResignMassive
																													index={row.id}
																													indexRow={indexRow}
																													isCircuit={
																														row?.isCircuit
																													}
																													selected={selected}
																													isFolder={true}
																													backToSignOneFile={
																														backToSignOneFile
																													}
																												/>
																											</TableCell>
																										</TableRow>
																									);
																								}
																							)}
																						</TableBody>
																					</Table>
																				</Box>
																			</Collapse>
																		</TableCell>
																	</TableRow>
																</>
															);
														})}
													</TableBody>
												) : (
													<TableBody>
														<TableCell />
														<TableCell>No se encontraron Documentos</TableCell>
														<TableCell />
														<TableCell />
														<TableCell />
														<TableCell />
													</TableBody>
												)}
											</Table>
										</TableContainer>
									</div>
									<Box
										display='flex'
										justifyContent='space-between'
										alignItems='center'
										width='100%'
										sx={{
											flexDirection: { xs: 'column', sm: 'row' },
										}}
									>
										<Box
											component='span'
											onClick={() => {
												loadDocuments();
											}}
											sx={{
												cursor: 'pointer',
												color: '#181C18',
												marginLeft: { xs: 0, sm: 6 },
												fontSize: '0.87rem',
												textDecoration: 'none',
												'&:hover': {
													color: '#E5682A',
												},
											}}
										>
											Ver más
										</Box>

										<TablePagination
											rowsPerPageOptions={[5, 10, 25]}
											component='div'
											count={rowsPending.length}
											rowsPerPage={rowsPerPage}
											page={page}
											onChangePage={handleChangePage}
											labelRowsPerPage={
												!(isHome && isSmallScreen) ? 'Filas por página' : null
											}
											onChangeRowsPerPage={handleChangeRowsPerPage}
										/>
									</Box>
									<Menu
										anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
										transformOrigin={{ vertical: 'top', horizontal: 'center' }}
										open={Boolean(orderMenu)}
										anchorEl={orderMenu}
										onClose={() => setOrderMenu(null)}
										getContentAnchorEl={null}
									>
										<MenuItem onClick={() => sort('nombrereal', 'asc')}>
											A - Z
										</MenuItem>
										<MenuItem onClick={() => sort('nombrereal', 'desc')}>
											Z - A
										</MenuItem>
										<MenuItem
											// onClick={() =>
											// 	sort(
											// 		tabSelected === 0 ? 'fechaFirma' : 'fechaCreacion',
											// 		'desc'
											// 	)
											// }
											onClick={() => {
												let sortBy;
												let sortOrder;

												if (tabSelected === 0) {
													sortBy = 'fechaFirma';
												} else if (tabSelected === 1) {
													sortBy = 'fechaCreacion';
												} else if (tabSelected === 2) {
													sortBy = 'fechaCreacion';
												}
												sortOrder = 'desc';
												sort(sortBy, sortOrder);
											}}
										>
											Más recientes
										</MenuItem>
										<MenuItem
											onClick={() =>
												sort(
													tabSelected === 0 ? 'fechaFirma' : 'fechaCreacion',
													'asc'
												)
											}
										>
											Más antiguos
										</MenuItem>
									</Menu>{' '}
								</>
							)}
							{tabSelected === 3 && (
								<PendingToObserve
									classes={classes}
									cutName={cutName}
									DateSign={DateSign}
									handleClick={handleClick}
									handleSelectAllClick={handleSelectAllClick}
									indexRow={indexRow}
									isFilterIsNull={isFilterIsNull}
									ref={ref}
									rowsFilter={rowsObserveFilter}
									selected={selected}
									setIndex={setIndex}
									StyledCheckbox={StyledCheckbox}
									orderMenu={orderMenu}
									setOrderMenu={setOrderMenu}
									loadDocuments={loadDocuments}
								/>
							)}
						</div>
					</Paper>
				</Container>
			</Grid>

			<ModalGetFIleCloud
				isOpen={statusModalCloudSelect}
				onClose={() => setStatusModalCloudSelect(false)}
				setStatusModalCloudSelect={setStatusModalCloudSelect}
				statusModalCloudSelect={statusModalCloudSelect}
			/>
			{statusModalAvanzado && (
				<ModalFirmaAvanzada
					isOpen={statusModalAvanzado}
					onClose={onClose}
					files={[]}
					filesSize={0}
					style={'style'}
					rows={rowsFilter}
					selected={[idOneFile]}
					setFiles={() => {}}
					refresh={refresh}
					setOpenedRowIndex={setOpenedRowIndex}
				/>
			)}
			{modalDeleteFiles && (
				<ModalDeleteFiles
					modalDeleteFiles={modalDeleteFiles}
					setModalDeleteFiles={setModalDeleteFiles}
					selected={selected}
					setSelected={setSelected}
					rows={rows}
					idsFolder={idsFolder}
					setLoading={setLoading}
					refresh={refresh}
					idDocsSelected={idDocsSelected}
					idCarpetCircuit={idCarpetCircuit}
					setOpenedRowIndex={setOpenedRowIndex}
					user={user}
				/>
			)}
			<Modal
				open={pop > 0}
				onClose={() => setStatusModalPop(false)}
				disableBackdropClick
				aria-labelledby='simple-modal-title'
				aria-describedby='simple-modal-description'
			>
				<Paper
					id={'paperElectro4'}
					elevation={3}
					className='sign-paper-source-signature'
				>
					<Typography className={'titlePopUp'}>
						{pop < 2 ? '¡Documento firmado!' : '¡Documentos firmados!'}
					</Typography>
					<div className='content'>
						<Button
							style={{ width: '150px', height: '41px' }}
							className={'btnGeneral18'}
							onClick={onClosePop}
						>
							Aceptar
						</Button>
					</div>
				</Paper>
			</Modal>

			{/* {loadingDocument && <Loading />} */}
			{loading && <Loading />}
		</>
	);
};

const mapStateToProps = state => ({
	rows: state.consultSignedDocument.documentSign,
	rowsPending: state.consultSignedDocument.pendingSign,
	rowsObserve: state.consultSignedDocument.pendingObserve,

	signOkFirmaAvanzada: state.consultSignedDocument.signOkFirmaAvanzada,
	loadingDocument: state.consultSignedDocument.loading,
	pop: state.sign.pop,
});

const mapDispatchToProps = {
	getCertificates: getCerificatesByUser,
	getSignManuscrita: getManuscritaByUser,
	obtenerBase64: getBase64OfSelectedFile,
	getListDocumentCF: getListSignedDocumentCF,
	deleteFile: deleteFile,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ConsultSignedDocuments);