import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import SignatureCanvas from 'react-signature-canvas';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { useSnackbar } from 'notistack';
import icoCerrar from '../../../../assets/img/icono-cerrar.svg';
import imgDefaultSign from '../../../../assets/img/gse.png';
import IcoAnadir from '../../../../assets/img/ico-anadir.png';
import IcoAnadirSel from '../../../../assets/img/ico-anadir-sel.png';
import IcoLimpiar from '../../../../assets/img/ico-limpiar.png';
import IcoLimpiarSel from '../../../../assets/img/ico-limpiar-sel.png';
import IcoManuscrita from '../../../../assets/img/ico-manuscrita.png';
import IcoManuscritaSel from '../../../../assets/img/ico-manuscrita-sel.png';
import IcoCargar from '../../../../assets/img/ico-cargar.png';
import IcoCargarSel from '../../../../assets/img/ico-cargar-sel.png';
import IcoPredefinido from '../../../../assets/img/ico-predefinido.svg';
import IcoPredefinidoSel from '../../../../assets/img/ico-predefinido-sel.svg';
import imgLoader from '../../../../assets/img/loader.gif';
import SignList from '../../../../components/Sign';
import ZoneUploadSign from '../../../../components/ZoneUploadSign';
import imgDibujaFirma from '../../../../assets/img/dibuja-firma.png';
import Grid from '@material-ui/core/Grid';
import '../../FirmaAvanzada/Sign.css';
import '../../FirmaAvanzada/Pdf.css';
import { modalSign } from '../../../../redux/actions/CircuitoFirmasAction';
import { Modal } from '@material-ui/core';
import {
	getManuscritaByUser,
	saveManuscritaAction,
} from '../../../../redux/actions/SignAction';

const AntTabs = withStyles({
	root: {
		borderBottom: '1px solid #ACACAC',
	},
	indicator: {
		backgroundColor: '#E55200',
	},
})(Tabs);

const AntTab = withStyles(theme => ({
	root: {
		textTransform: 'none',
		minWidth: 72,
		fontWeight: theme.typography.fontWeightBold,
		fontSize: 19,
		'&:hover': {
			color: '#E55200',
			opacity: 1,
		},
		'&$selected': {
			color: '#E55200',
			fontWeight: theme.typography.fontWeightBold,
		},
		'&:focus': {
			color: '#E55200',
		},
	},
	selected: {},
}))(props => <Tab disableRipple {...props} />);

const useStyles = makeStyles(theme => ({
	modal: {
		width: '100%',
		height: '100vh',
		backgroundColor: '#00000099',
		position: 'fixed',
		top: 0,
		left: 0,
	},
	iconManuscrita: {
		width: '40px',
		height: '40px',
		background: '#EDEDED 0% 0% no-repeat padding-box',
		backgroundImage: `url(${IcoManuscrita})`,
		boxShadow: '0px 2px 0px #00000033',
		border: '0.6078676581382751px solid #4F4F4F',
		borderRadius: '5px',
		marginRight: '16px',
		'&:hover': {
			background: '#F5AE19 0% 0% no-repeat padding-box',
			backgroundImage: `url(${IcoManuscritaSel})`,
			border: 'none',
		},
	},
	iconCargar: {
		width: '40px',
		height: '40px',
		background: '#EDEDED 0% 0% no-repeat padding-box',
		backgroundImage: `url(${IcoCargar})`,
		boxShadow: '0px 2px 0px #00000033',
		border: '0.6078676581382751px solid #4F4F4F',
		borderRadius: '5px',
		marginRight: '16px',
		'&:hover': {
			background: '#F5AE19 0% 0% no-repeat padding-box',
			backgroundImage: `url(${IcoCargarSel})`,
			border: 'none',
		},
	},
	iconPedefinido: {
		width: '39px',
		height: '39px',
		background: '#EDEDED 0% 0% no-repeat padding-box',
		backgroundImage: `url(${IcoPredefinido})`,
		boxShadow: '0px 2px 0px #00000033',
		border: '0.6078676581382751px solid #4F4F4F',
		borderRadius: '5px',
		marginRight: '16px',
		'&:hover': {
			background: '#F5AE19 0% 0% no-repeat padding-box',
			backgroundImage: `url(${IcoPredefinidoSel})`,
			border: 'none',
		},
	},
	iconPedefinidoSel: {
		width: '39px',
		height: '39px',
		background: '#F5AE19 0% 0% no-repeat padding-box',
		backgroundImage: `url(${IcoPredefinidoSel})`,
		border: 'none',
		borderRadius: '5px',
		marginRight: '16px',
		'&:hover': {
			background: '#F5AE19 0% 0% no-repeat padding-box',
			backgroundImage: `url(${IcoPredefinidoSel})`,
			border: 'none',
		},
		'&:disabled': {
			background: '#F5AE19 0% 0% no-repeat padding-box',
			backgroundImage: `url(${IcoPredefinidoSel})`,
			border: 'none',
		},
	},
	buttonCargarSel: {
		width: '40px',
		height: '40px',
		background: '#F5AE19 0% 0% no-repeat padding-box',
		backgroundImage: `url(${IcoCargarSel})`,
		border: 'none',
		borderRadius: '5px',
		marginRight: '16px',
		'&:hover': {
			background: '#F5AE19 0% 0% no-repeat padding-box',
			backgroundImage: `url(${IcoCargarSel})`,
			border: 'none',
		},
	},
	buttonManuscritaSel: {
		width: '40px',
		height: '40px',
		background: '#F5AE19 0% 0% no-repeat padding-box',
		backgroundImage: `url(${IcoManuscritaSel})`,
		border: 'none',
		borderRadius: '5px',
		marginRight: '16px',
		'&:hover': {
			background: '#F5AE19 0% 0% no-repeat padding-box',
			backgroundImage: `url(${IcoManuscritaSel})`,
		},
	},
	buttonPredefinidoSel: {
		width: '40px',
		height: '40px',
		background: '#F5AE19 0% 0% no-repeat padding-box',
		backgroundImage: `url(${IcoPredefinidoSel})`,
		border: 'none',
		borderRadius: '5px',
		'&:hover': {
			background: '#F5AE19 0% 0% no-repeat padding-box',
			backgroundImage: `url(${IcoPredefinidoSel})`,
		},
	},
	iconAnadir: {
		marginLeft: '10px',
		width: '26px',
		height: '26px',
		borderRadius: '5px',
		backgroundImage: `url(${IcoAnadir})`,
		'&:hover': {
			backgroundImage: `url(${IcoAnadirSel})`,
		},
	},
	iconLimpiar: {
		width: '26px',
		height: '26px',
		borderRadius: '5px',
		backgroundImage: `url(${IcoLimpiar})`,
		'&:hover': {
			backgroundImage: `url(${IcoLimpiarSel})`,
		},
	},
	paper: {
		margin: 'auto',
		minHeight: '305px',
		maxHeight: '350px',
	},
	labelCanvas: {
		position: 'absolute',
		font: 'normal normal bold 24px/23px Muli',
		textShadow: '0px 0px 5px #000000',
		letterSpacing: '0px',
		color: '#FFFFFF',
		width: '70%',
		textAlign: 'center',
		top: '42%',
		left: '50%',
		transform: 'translateX(-50%)',
	},
	selected: {
		position: 'absolute',
		top: '0',
		backgroundColor: '#00000066',
		width: '480px',
		height: '250px',
		borderRadius: '5px',
	},
	buttonDisabled: {},
	continue: {
		backgroundColor: '#E55200',
		color: 'white',
		padding: '8px 24px',
		fontSize: '16px',
		alignItems: 'center',
		cursor: 'pointer',
		fontWeight: '800',
		fontFamily: 'Mulish',
		border: '1px solid #E55200',
		textTransform: 'none',
		borderRadius: '8px',
		display: 'initial !important',
		marginBottom: '37px !important',
		'&:hover': {
			backgroundColor: '#E55200',
		},
		'&.Mui-disabled': {
			color: 'white',
		},
	},
	gap: {
		gap: '50px',
		marginTop: '20px',
	},
}));

function TabPanel(props) {
	const { children, value, index, ...other } = props;
	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={0}>{children}</Box>}
		</div>
	);
}

const IconClose = () => {
	return <img src={icoCerrar} />;
};

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
};

const ModalSign = props => {
	const {
		setReSign,
		getSignManuscrita,
		saveManuscrita,
		setTodosLosCampos,
		todosLosCampos,
		campoFirma,
		firmarConCampos,
		cliente = false,
		firmanteCorreo = true,
		firmasHechas,
		setFirmasHechas,
		firmasNecesarias,
		userFirmaya = true,
		targetId,
		setTargetId,
		eliminarDataFirma,
	} = props;
	const [valueMenu, setValueMenu] = useState(null);
	const [loading, setLoading] = useState(true);
	const [draw, setDraw] = useState(true);
	const [isAgregar, setAgregar] = useState(false);
	const [isDigital, setDigital] = useState(false);
	const [isUpload, setUpload] = useState(false);
	const [isLoad, setLoad] = useState(false);
	const [modal, setModal] = useState(true);
	const [activeDibujar, setActiveDibujar] = useState(false);
	const [base64, setBase64] = useState(null);
	const [drawYourSign, setDrawYourSign] = useState(null);
	const [imgLoad, setImgLoad] = useState(null);
	const { showComplete, home, sendData, setStateFirmas } = props;
	const [signSelected, setSignSelected] = useState(null);
	const [canvasWidth, setCanvasWidth] = useState(0);
	const [canvasHeight, setCanvasHeight] = useState(0);
	const { enqueueSnackbar } = useSnackbar();
	var sigPad = React.useRef();
	const states = useSelector(state => state.CircuitoFirmasReducer);
	const dispatch = useDispatch();
	const [all, setAll] = useState(false);
	useEffect(() => {
		(async () => {
			// await getSignManuscrita(enqueueSnackbar);
			await handleDibujar();
			if (home == true) {
				setValueMenu(1);
				setLoading(false);
			} else {
				setValueMenu(0);
				initMessageCanvas();
			}
			if (showComplete == true) {
				props.setSignPredefined(0);
			}
			setLoading(false);
		})();
	}, []);

	useEffect(() => {
		setTodosLosCampos(all);
	}, [all]);

	window.addEventListener(
		'resize',
		function (event) {
			if (document.getElementById('signature-canvas') && activeDibujar) {
				setCanvasWidth(document.getElementById('signature-canvas').clientWidth);
				setBase64(null);
				setActiveDibujar(false);
				setCanvasHeight(
					document.getElementById('signature-canvas').clientHeight
				);
				document.getElementById('box-icons-buttons').style.visibility =
					'hidden';
			}
		},
		true
	);

	const close = () => {
		dispatch(modalSign(false));
	};

	const initMessageCanvas = () => {
		setTimeout(() => {
			var canvas = document.getElementById('sig-pad-canvas');
			setCanvasWidth(document.getElementById('signature-canvas').clientWidth);
			setCanvasHeight(document.getElementById('signature-canvas').clientHeight);
			var ctx = canvas.getContext('2d');
			if (base64 != null) {
				var image = new Image();
				document.getElementById('box-icons-buttons').style.visibility =
					'visible';
				image.onload = function () {
					ctx.drawImage(image, 0, 0);
				};
				image.src = base64;
			} else {
				ctx.font = 'normal normal normal 18px/22px Muli';
				ctx.fillStyle = '#9D9D9C';
				ctx.textAlign = 'center';
				ctx.fillText(
					'Dibuja tu firma aquí',
					canvas.width / 2,
					canvas.height / 2
				);
				addEventsCanvas();
				setDrawYourSign(sigPad.current.toDataURL());
			}
		}, 20);
	};

	const addEventsCanvas = () => {
		var canvas = sigPad.current.getCanvas();
		var ctx = canvas.getContext('2d');
		ctx.font = 'normal normal normal 18px/22px Muli';
		ctx.fillStyle = '#9D9D9C';
		ctx.textAlign = 'center';
		canvas.addEventListener(
			'mousedown',
			function () {
				if (sigPad.current.toData().length <= 0) {
					ctx.clearRect(0, 0, canvas.width, canvas.height);
				}
				setActiveDibujar(true);
				setBase64(sigPad.current.toDataURL());
				document.getElementById('box-icons-buttons').style.visibility =
					'visible';
			},
			true
		);

		canvas.addEventListener(
			'touchstart',
			function () {
				if (sigPad.current.toData().length <= 0) {
					ctx.clearRect(0, 0, canvas.width, canvas.height);
				}
				setBase64(sigPad.current.toDataURL());
				document.getElementById('box-icons-buttons').style.visibility =
					'visible';
			},
			true
		);

		canvas.addEventListener(
			'mouseenter',
			function () {
				if (sigPad.current.toData().length <= 0) {
					ctx.clearRect(0, 0, canvas.width, canvas.height);
				}
			},
			true
		);
		canvas.addEventListener(
			'mouseout',
			function () {
				if (sigPad.current.toData().length <= 0) {
					clearMessageCanvas();
				}
			},
			true
		);
		canvas.addEventListener(
			'touchstart',
			function () {
				if (sigPad.current.toData().length <= 0) {
					ctx.clearRect(0, 0, canvas.width, canvas.height);
					document.getElementById('box-icons-buttons').style.visibility =
						'visible';
				}
				setActiveDibujar(true);
			},
			true
		);
	};

	const clearMessageCanvas = () => {
		var canvas = document.getElementById('sig-pad-canvas');
		var ctx = canvas.getContext('2d');
		ctx.clearRect(0, 0, canvas.width, canvas.height);
		ctx.font = 'normal normal normal 18px/22px Muli';
		ctx.fillStyle = '#9D9D9C';
		ctx.textAlign = 'center';
		ctx.fillText('Dibuja tu firma aquí', canvas.width / 2, canvas.height / 2);
	};

	const clearLoadImage = () => {
		if (draw === true) {
			clearCanvas();
			addEventsCanvas();
			document.getElementById('signature-canvas').style.pointerEvents = 'auto';
		}
	};

	const clearCanvas = () => {
		if (sigPad.current !== null) {
			sigPad.current.clear();
			clearMessageCanvas();
		}
	};

	const clear = () => {
		document.getElementById('box-icons-buttons').style.visibility = 'hidden';
		if (props.setSignPredefined != null) {
			props.setSignPredefined(0);
		}
		if (isDigital == true) {
			setDigital(false);
			setActiveDibujar(true);
			setDraw(true);
			initMessageCanvas();
		}
		if (isLoad == false) {
			if (isUpload === false && isDigital === false && base64 === null) {
				clearCanvas();
				setBase64(null);
			} else if (isUpload === true) {
				if (base64 !== null) {
					setBase64(null);
				}
				setUpload(false);
				clearLoadImage();
			} else if (base64 !== null) {
				setBase64(null);
				clearLoadImage();
			}
		}
		setCanvasWidth(document.getElementById('signature-canvas').clientWidth);
		setCanvasHeight(document.getElementById('signature-canvas').clientHeight);
		setSignSelected(null);
	};

	const clearDeleteSign = async id => {
		if (signSelected == id) {
			setValueMenu(0);
			clear();
			setSignSelected(null);
		}
	};

	const selectSign = async (img, id) => {
		setValueMenu(0);
		setDraw(true);
		if (base64 == null) {
			setCanvasWidth(document.getElementById('signature-canvas').clientWidth);
			setCanvasHeight(document.getElementById('signature-canvas').clientHeight);
		}
		setTimeout(async () => {
			setUpload(true);
			setBase64(img);
			setActiveDibujar(false);
			setLoad(false);
			setDigital(false);
			setSignSelected(id);
			await drawInCanvas(img, false);
		}, 20);
	};

	const loadSign = async img => {
		setUpload(true);
		setBase64(img);
		setLoad(false);
		setDraw(true);
		setActiveDibujar(false);
		await drawInCanvas(img, true);
		setCanvasWidth(document.getElementById('signature-canvas').clientWidth);
		setCanvasHeight(document.getElementById('signature-canvas').clientHeight);
	};

	const drawInCanvas = async (base64, load) => {
		var canvas = sigPad.current.getCanvas();
		var ctx = canvas.getContext('2d');
		document
			.getElementById('sig-pad-canvas')
			.getContext('2d')
			.clearRect(0, 0, canvas.width, canvas.height);
		var imageObj = new Image();
		imageObj.src = base64;
		imageObj.onload = function () {
			if (load === false) {
				ctx.drawImage(
					imageObj,
					Math.round(canvas.width / 2 - imageObj.width / 2),
					Math.round(canvas.height / 2 - imageObj.height / 2)
				);
			} else {
				var scale = Math.min(
					canvas.width / imageObj.width,
					canvas.height / imageObj.height
				);
				var x = canvas.width / 2 - (imageObj.width / 2) * scale;
				var y = canvas.height / 2 - (imageObj.height / 2) * scale;
				ctx.drawImage(
					imageObj,
					x,
					y,
					imageObj.width * scale,
					imageObj.height * scale
				);
			}
		};
		document.getElementById('signature-canvas').style.pointerEvents = 'none';
		document.getElementById('box-icons-buttons').style.visibility = 'visible';
	};

	const handleFirmar = () => {
		setValueMenu(0);
		if (
			base64 != null &&
			activeDibujar == false &&
			isLoad == false &&
			!isDigital
		) {
			setTimeout(() => {
				drawInCanvas(base64);
			}, 250);
		} else if (draw == true) {
			initMessageCanvas();
		}
	};

	function handleVerFirmas() {
		if (draw == true) {
			if (
				sigPad.current.toDataURL() != null &&
				sigPad.current.toDataURL() != drawYourSign
			) {
				setBase64(sigPad.current.toDataURL());
			}
		}
		setValueMenu(1);
	}

	async function handleDibujar() {
		if (draw == false) {
			setLoad(false);
			if (base64)
				document.getElementById('box-icons-buttons').style.visibility =
					'visible';
			setDigital(false);
			setDraw(true);
			if (base64 != null && isUpload) {
				setActiveDibujar(false);
				setTimeout(() => {
					drawInCanvas(base64);
				}, 250);
			} else {
				setActiveDibujar(true);
				initMessageCanvas();
			}
		}
	}

	function getDataUrl() {
		var imagen = document.createElement('img');
		imagen.src = imgDibujaFirma;
		return imagen.src;
	}

	async function handleCargar() {
		setDigital(false);
		setDraw(false);
		document.getElementById('box-icons-buttons').style.visibility = 'hidden';
		if (draw == true) {
			if (sigPad.current.toDataURL() != drawYourSign) {
				setBase64(sigPad.current.toDataURL());
			}
			setActiveDibujar(false);
		}
		setLoad(true);
	}

	async function drawDigitalSign() {
		let allowSign = true;
		document.getElementById('box-icons-buttons').style.visibility = 'hidden';
		let docAct = null;
		let listMini = document.getElementsByClassName('pdf-canvas-mini');
		if (listMini) {
			docAct = Array.from(listMini)
				.find(mini => mini.classList.contains('active-sheet'))
				.getAttribute('data-documento');
		}

		sendData.documents.map(document => {
			if (document.uuid == docAct) {
				document.typeSignature.map(type => {
					if (type.addDataCert == 1) {
						allowSign = false;
					}
				});
			}
		});
		if (allowSign == true) {
			if (sigPad.current && sigPad.current.toDataURL() != drawYourSign) {
				setBase64(sigPad.current.toDataURL());
			}
			setDraw(false);
			setActiveDibujar(false);
			setLoad(false);
			setDigital(true);
		} else {
			enqueueSnackbar('Solo se permite una firma predefinida por documento', {
				variant: 'warning',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			});
		}
	}

	const changeTabManual = () => {
		setValueMenu(0);
		setDraw(true);
		if (isLoad) {
			setLoad(false);
			initMessageCanvas();
		} else if (base64 != null && !activeDibujar && !isLoad && !isDigital) {
			setTimeout(() => {
				drawInCanvas(base64);
			}, 250);
		} else if (draw == true) {
			initMessageCanvas();
		}
	};

	const trim = () => {
		eliminarDataFirma(targetId);
		if (campoFirma) {
			if (draw == true) {
				var canvas = document.getElementById('sig-pad-canvas').toDataURL();
				if (canvas == getDataUrl(imgDibujaFirma) || draw == false) {
					enqueueSnackbar('Debe dibujar o cargar una firma', {
						variant: 'warning',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					});
				} else {
					props.setTypeImg(props.manuscrita);

					firmarConCampos(
						sigPad.current.getTrimmedCanvas().toDataURL('image/png'),
						'manuscrita'
					);
					close();
				}
			} else {
				if (isDigital != true) {
					enqueueSnackbar('Debe dibujar o cargar una firma', {
						variant: 'warning',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					});
				} else {
					if (isDigital == true) {
						props.firmarConCampos('', 'digital');
						props.setSignImage(imgDefaultSign, true);
						close();
					}
				}
			}
		} else {
			if (draw == true) {
				var canvas = document.getElementById('sig-pad-canvas').toDataURL();
				if (canvas == getDataUrl(imgDibujaFirma) || draw == false) {
					enqueueSnackbar('Debe dibujar o cargar una firma', {
						variant: 'warning',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					});
				} else {
					props.setTypeImg(props.manuscrita);
					setReSign(false);
					enqueueSnackbar('Ubicar el Campo de firma en la posición deseada', {
						variant: 'info',
						anchorOrigin: { vertical: 'top', horizontal: 'right' },
						autoHideDuration: 3000,
						ContentProps: {
							style: {
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between', // Espacia el mensaje y el botón
								width: '100%', // Asegura que ocupe todo el ancho disponible
								whiteSpace: 'nowrap', // Evita saltos de línea
							},
						},
					});

					props.setSignImage(
						sigPad.current.getTrimmedCanvas().toDataURL('image/png')
					);
					close();
				}
			} else {
				if (isDigital != true) {
					enqueueSnackbar('Debe dibujar o cargar una firma', {
						variant: 'warning',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					});
				} else {
					if (isDigital == true) {
						enqueueSnackbar('Ubicar el Campo de firma en la posición deseada', {
							variant: 'info',
							anchorOrigin: { vertical: 'top', horizontal: 'right' },
							autoHideDuration: 3000,
							ContentProps: {
								style: {
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between', // Espacia el mensaje y el botón
									width: '100%', // Asegura que ocupe todo el ancho disponible
									whiteSpace: 'nowrap', // Evita saltos de línea
								},
							},
						});
						// props.aplicarFirma(true, props.digital);
						props.setDigitalSign(true);
						setReSign(false);
						props.setSignPredefined(1);
						props.setDigitalImg(imgDefaultSign);
						props.setTypeImg(props.manuscrita);
						props.setSignImage(imgDefaultSign, true);
						close();
					}
				}
			}
		}
	};

	const saveManuscritaImage = async () => {
		if (isDigital == true) {
			enqueueSnackbar('La firma predefinida no se puede añadir a mis firmas', {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			});
			return;
		}
		var img = document.getElementById('sign-img-load');
		if (!img) {
			if (isLoad == true) {
				enqueueSnackbar('Por favor cargue una imagen antes de añadir', {
					variant: 'warning',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				});
			} else if (sigPad.current.toDataURL() == getDataUrl(imgDibujaFirma)) {
				enqueueSnackbar('Por favor dibuje una firma antes de añadir', {
					variant: 'warning',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				});
			} else if (
				parseInt(sigPad.current.getTrimmedCanvas().width) < 50 ||
				parseInt(sigPad.current.getTrimmedCanvas().height) < 50
			) {
				enqueueSnackbar(
					'La firma es muy pequeña, por favor dibuje una firma mas grande',
					{
						variant: 'warning',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					}
				);
				clear();
			} else {
				const imagenmanuscrita = {
					base64: sigPad.current.getTrimmedCanvas().toDataURL().slice(22),
					preterminado: 0,
				};
				await saveManuscrita(imagenmanuscrita, enqueueSnackbar);
				await getSignManuscrita(enqueueSnackbar);
				setAgregar(true);
				setTimeout(() => {
					clear();
					setAgregar(false);
				}, 2000);
			}
		}
	};

	const classes = useStyles();

	return (
		<div className={classes.modal}>
			<Paper tabIndex={-1} className='sign-paper-man'>
				<Paper elevation={0} className='sign-paper-man-center'>
					<Grid container direction='row' justifyContent='space-between'>
						<Grid item xs={11}>
							{/* -----Menu del modal, firmas y mis firmas----- */}
							{firmanteCorreo === true ? (
								<AntTabs
									value={valueMenu}
									variant='fullWidth'
									aria-label='Selector de firma manuscrita'
									className={'tabs-manuscrita'}
								>
									<AntTab id='tab1' label='Firmar' onClick={handleFirmar} />
								</AntTabs>
							) : (
								<AntTabs
									value={valueMenu}
									variant='fullWidth'
									aria-label='Selector de firma manuscrita'
									className={'tabs-manuscrita'}
								>
									<AntTab id='tab1' label='Firmar' onClick={handleFirmar} />
									<AntTab
										id='tab2'
										label='Mis firmas'
										onClick={handleVerFirmas}
									></AntTab>
								</AntTabs>
							)}
						</Grid>
						<Grid item xs={1}>
							{/* Icono de cerrar  */}
							<IconButton
								edge='start'
								aria-label='upload file'
								onClick={() => close()}
								className='buttonPlusZoneUpload'
							>
								<IconClose />
							</IconButton>
						</Grid>
					</Grid>
					{loading ? (
						<img src={imgLoader} width='200' style={{ width: '100%' }} />
					) : (
						<div>
							<TabPanel value={valueMenu} index={0}>
								<Box spacing={3} justifyContent='flex-start'>
									<Grid container className='button-sign-container'>
										<Grid item xs style={{ flexGrow: '0' }}>
											{/* Cargar una firma desde mi computador */}
											<IconButton
												className={
													isLoad
														? 'button-sign-sel button-cargar-sel'
														: 'button-sign button-cargar'
												}
												data-title='Cargar firma'
												onClick={handleCargar}
											>
												<span>Cargar firma</span>
											</IconButton>
										</Grid>
										<Grid item xs>
											{/* Dibujar la firma, este puede ser opcional XD */}
											<IconButton
												className={
													draw
														? 'button-sign-sel button-dibujar-sel'
														: 'button-sign button-dibujar'
												}
												data-title='Dibujar firma'
												onClick={handleDibujar}
											>
												<span>Dibujar firma</span>
											</IconButton>
										</Grid>

										{/* Este es el icono de una firma predefinida------------------------- */}
										{/* El showComplete es algo que se envia por props, por el momento comentare esta parte. */}
										{showComplete && cliente && (
											<Grid item xs>
												<IconButton
													className={
														isDigital
															? 'button-sign-sel button-digital-sel'
															: 'button-sign button-digital'
													}
													data-title='Firma predefinida'
													onClick={drawDigitalSign}
												>
													<span>Firma predeterminada</span>
												</IconButton>
											</Grid>
										)}
									</Grid>

									{/* Si draw esta en true, mostrar la parte para dibujar la firma */}
									{draw == true && (
										<Box
											id='signature-canvas'
											style={{ marginTop: '15px', position: 'relative' }}
										>
											<SignatureCanvas
												id='pcCanvas'
												name='pcCanvas'
												canvasProps={{
													className: 'sig-pad',
													id: 'sig-pad-canvas',
													width: canvasWidth + 'px',
													height: canvasHeight + 'px',
												}}
												ref={sigPad}
											></SignatureCanvas>
											{isAgregar && (
												<div id={'selected'} className={classes.selected}>
													<div className='drag-sign' id='drag-sign'>
														<label
															id={'label-selected'}
															className={classes.labelCanvas}
														>
															Firma añadida a "Mis firmas"
														</label>
													</div>
												</div>
											)}
										</Box>
									)}
									{isLoad && (
										<Box justifyContent='center' className={'isLoad'}>
											<Box style={{ position: 'relative' }}>
												{imgLoad ? (
													<div className='drag-sign' id='drag-sign'>
														<img
															height={'250px'}
															width={'auto'}
															src={imgLoad}
														/>
													</div>
												) : (
													<ZoneUploadSign
														loadSign={loadSign}
														setImgLoad={setImgLoad}
													/>
												)}
											</Box>
										</Box>
									)}
									{isDigital && (
										<Box justifyContent='center' style={{ marginTop: '15px' }}>
											<Box style={{ position: 'relative' }}>
												<div className='drag-sign' id='drag-sign'>
													<img src={imgDefaultSign}></img>
													<p className='predefined-alert'>
														Los datos reales de esta firma se verán una vez se
														seleccione el certificado.
													</p>
												</div>
											</Box>
										</Box>
									)}

									<div style={{ display: 'flex', flexDirection: 'column' }}>
										{userFirmaya && firmasNecesarias > 1 && (
											<div className='wraper'>
												<input
													type='checkbox'
													name='all'
													id='all'
													value='1'
													onChange={e => setAll(e.target.checked)}
													className='wraperInput'
												/>
												<label className='wraperLabel' htmlFor='all'>
													Incluir esta firma a todos los campos
												</label>
											</div>
										)}

										<Box
											display='flex'
											flexDirection='row'
											justifyContent='center'
											margin='20px 0 0 0'
										>
											<div
												style={{
													display: 'flex',
													justifyContent: 'flex-end',
													width: '90%',
												}}
											>
												{/* -------------------------------------------------firma------------------------------------- */}
												<Button
													id='apply'
													className={classes.continue}
													disabled={(base64 == null && !isDigital) || isLoad}
													disableElevation
													onClick={trim}
												>
													Aplicar Firma
												</Button>
											</div>
											<div
												style={{
													display: 'flex',
													justifyContent: 'flex-end',
													width: '40%',
												}}
											>
												{firmanteCorreo === true ? (
													<>
														{/* icono de borrar firma, es importante dejarlo por si la persona se equivoca al hacer la firma, pueda borrar y volverla a hacer */}
														<Box id='box-icons-buttons'>
															<IconButton
																className='icon-button icon-limpiar mostrar'
																data-title='Limpiar'
																onClick={clear}
															>
																<span>Borrar</span>
															</IconButton>
														</Box>
													</>
												) : (
													<>
														{/* icono de borrar firma, es importante dejarlo por si la persona se equivoca al hacer la firma, pueda borrar y volverla a hacer */}
														<Box id='box-icons-buttons'>
															<IconButton
																className='icon-button icon-limpiar mostrar'
																data-title='Limpiar'
																onClick={clear}
															>
																<span>Borrar</span>
															</IconButton>
															{/* Icono para guardar la firma en mis firmas...  */}
															<IconButton
																className='icon-button icon-anadir mostrar'
																data-title='Añadir a mis firmas'
																onClick={saveManuscritaImage}
															>
																<span>
																	Añadir <br />a mi lista
																</span>
															</IconButton>
														</Box>
													</>
												)}
											</div>
										</Box>
									</div>
								</Box>
							</TabPanel>

							{/* Creo que esta tabla es la de mis firmas */}
							<TabPanel value={valueMenu} index={1} className='tab-signs'>
								<Box style={{ paddingTop: '40px' }}>
									<SignList
										selectSign={selectSign}
										showComplete={showComplete}
										actionAdd={() => changeTabManual()}
										clear={clearDeleteSign}
									/>
								</Box>
							</TabPanel>
						</div>
					)}
				</Paper>
			</Paper>
		</div>
	);
};
const mapDispatchToProps = {
	getSignManuscrita: getManuscritaByUser,
	saveManuscrita: saveManuscritaAction,
};
const mapStateToProps = ({ sign }) => ({
	listManuscrita: sign.listManuscrita,
	responseSign: sign.responseSign,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalSign);
