import { LOADING, LOGOUT, SET_USER_INFO } from '../types/AuthTypes';

const INITIAL_STATE = {
  loading: false,
  userInfo: undefined,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
      case LOGOUT:
      return {
        ...state,
        userInfo: null, // Limpia la información del usuario
      };
    default:
      return state;
  }
};